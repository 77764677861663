import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
// import { Editor } from "@tinymce/tinymce-react";
import { getTutorialCategory, postEditChapter } from "../../../../api/tutorial";
import { EDITOR_KEY, ERROR_SOMETHING_WENT_WRONG, slugToNormal, TINY_EDITOR_KEY } from "../../../../utils/utils";

import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import FAQItem from "../faq";
// Register your Syncfusion license key
registerLicense(EDITOR_KEY);

function AddChapter() {
    const moderateEditorRef = useRef(null);
    const moderateFaqEditorRef = useRef(null);
    const faqEditorRefs = useRef([]);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const { globalToken } = useAuth();
    const [faqContainers, setFaqContainers] = useState([{ question: '', answer: '' }]);
    const [choosenTutorial, setChoosenTutorial] = useState(JSON.parse(localStorage.getItem('t__e') || '{}'));

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode','CreateTable', 'FullScreen', '|', 'Undo', 'Redo']
    };
    const quickToolbarSettings = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'],
        link: ['Open', 'Edit', 'UnLink']
    };

    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Chapter Title is required"),
        description: Yup.string().required("Description is required"),
    });

    const navigate = useNavigate();

    const handlePlusClick = () => {
        // Add a new FAQ container and expand its size
        setFaqContainers([...faqContainers, { question: '', answer: '' }]);
    };

    useEffect(() => {
        faqEditorRefs.current = faqContainers.map((_, i) => faqEditorRefs.current[i] || React.createRef());
    }, [faqContainers]);

    const handleMinusClick = (index) => {
        // Remove the relevant FAQ container
        setFaqContainers(faqContainers.filter((_, i) => i !== index));
    };

    useEffect(() => {
        
        return () => {
            localStorage.removeItem('t__e');
        }

    }, [])
    

    const handleAddChapter = async (values) => {
        setBtnLoading(true);
        // alert(JSON.stringify({ token: globalToken, description:values?.description, title:values?.title, tutorialId:choosenTutorial?.id, ordering:1, parentId:0, faq: faqContainers }))
        // return;
        const resPostEditChapter = await postEditChapter({ token: globalToken, description:values?.description, title:values?.title, tutorialId:choosenTutorial?.id, ordering:1, parentId:0, faq: faqContainers });
        
        
        if (resPostEditChapter?.success) {
            toast.success(resPostEditChapter?.message);
            setBtnLoading(false)
            return true;
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
            setBtnLoading(false)
            return false;
        }
        
        
    }



    const formData = [
        {
            id: 'title',
            title: 'Chapter Title',
            type: 'text',
            name: 'title',
            placeholder: 'Chapter Title',
            required: true,
            editable: true
        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Description',
            required: true,
            editable: true,
            width: true
        }
    ]




    return (
        <>
            {/* <BreadCrumbs /> */}
                <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>Tutorials</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/tutorials/my-tutorials' style={{ fontSize: 14, textDecoration: 'none' }}>My Tutorials</NavLink>
                            </li>
                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(choosenTutorial?.title)}
                            </li>
                         <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                Add Chapter
                            </li>
                        </ol>
                    </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                title: "",
                                description: ""
                            }}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={async (values, { resetForm }) => {
                                if (values) {
                                    const success = await handleAddChapter(values);
                                    if (success) {
                                        resetForm(); // Reset the form after successful submission
                                        if (moderateEditorRef.current) {
                                            moderateEditorRef.current.value = ''; // Clear editor content
                                          }
                                          faqEditorRefs.current.forEach(ref => {
                                            if (ref?.current) {
                                                ref.current.value = ''; // Reset FAQ editor content
                                                }
                                            });
                                            setFaqContainers([{ question: '', answer: '' }]);                                
                                    }
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map((fieldMeta, _i) => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                {/* <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }} 
                                                                /> */}
                                                                <RichTextEditorComponent ref={moderateEditorRef}  height={250} toolbarSettings={toolbarSettings}
                                                                    quickToolbarSettings={quickToolbarSettings}
                                                                    insertImageSettings={{saveFormat: "Base64" }}
                                                                                                        id={'achapter'+_i}
                                                                                                        change={(content) => {
                                                                                                            setFieldValue(fieldMeta?.id, content.value);
                                                                                                        }}
                                                                                                        placeholder="Enter answer here">
                                                                                                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]}/>
                                                </RichTextEditorComponent>
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : <>
                                                                <input
                                                                    disabled={!fieldMeta?.editable}
                                                                    type={fieldMeta?.type}
                                                                    name={fieldMeta?.id}
                                                                    autoComplete={'off'}
                                                                    id={fieldMeta?.name}
                                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                    placeholder={fieldMeta?.title}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values[fieldMeta?.id] || ""}
                                                                />
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>}

                                                </div>
                                            ))
                                        }

                                         <label htmlFor={'faq'}
                                            className={`block mb-2 text-sm font-medium position-relative text-gray-900 dark:text-gray-500`}
                                        >
                                            FAQ
                                            <button className="btn-plus" type="button" onClick={() => handlePlusClick()}><i className="fa fa-plus"></i></button>
                                        </label>
                                        <div className="col-md-12">                                            {
                                            faqContainers?.length ? faqContainers?.map((fq, index) => (
                                                <FAQItem key={fq.id} TINY_EDITOR_KEY={TINY_EDITOR_KEY} moderateEditorRef={faqEditorRefs.current[index]} faq={faqContainers} fq={fq} setFaqContainers={setFaqContainers} faqIndex={index} handleMinusClick={handleMinusClick} setFieldValue={setFieldValue} />
                                            )) : null
                                        }
                                        </div>
                                    </div>
                                    <div className="col-md-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={btnLoading}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {btnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default AddChapter;
