import moment, { relativeTimeRounding } from 'moment';
import { PostData } from '../api/feeds/PostData';
import { getUserInfo } from '../api/OurService';
import CryptoJS, { AES } from 'crypto-js';
import { toast } from 'react-toastify';
import { getRecentVisits, saveRecentVisits } from '../api/dashboard';

// export const BASE_URL = "https://www.tuteehub.com/api/";
export const BASE_URL = "https://www.tuteehub.com/api/v3/";
export const TUTEEHUB_MAIN_URL = "https://www.tuteehub.com/";
export const BASE_URL_OLD = "https://www.tuteehub.com/api/";
export const QBANK_BASE_URL = "https://questionbank.tuteehub.com/api/v3/";
// export const SHORT_TASK_BASE_URL = "http://128.199.215.223/api/gigs/";
export const SHORT_TASK_BASE_URL = "https://gigs.tuteehub.com/api/v3/gigs/";
export const CAREER_NEWS_BASE_URL = "https://careernews.tuteehub.com/api/v3/cn/";
export const WORK_BASE_URL = "https://work.tuteehub.com/api/v3/";
export const GIGS_BASE_URL = "https://gigs.tuteehub.com/";
export const INTBANK_BASE_URL = "https://interviewquestions.tuteehub.com/api/v3/";
export const FF_BASE_URL = "https://fullforms.tuteehub.com/api/v3/";
export const DC_BASE_URL = "https://dictionary.tuteehub.com/api/v3/";
export const EDITOR_KEY = "Ngo9BigBOggjHTQxAR8/V1NDaF1cX2hIYVZpR2Nbek5yflFBalxYVAciSV9jS3pSdEVlWX1ad3VVQ2hdVw==";
// https://www.syncfusion.com/account/claim-license-key?pl=SmF2YVNjcmlwdA==&vs=Mjc=&utm_source=es_license_validation_banner&utm_medium=listing&utm_campaign=license-information
export const EX_BASE_URL = "https://exams.tuteehub.com/api/v3/";
export const FEEDS_API_Basepath = "https://feeds-api.tuteehub.com/api/v3/fd/";
export const FEEDS_BASE_URL = "https://feeds.tuteehub.com/";
export const QUOTES_BASE_URL = "https://quotes.tuteehub.com/api/v3/sht/";
export const QUOTES_BASE_URL_V1 = "https://quotes.tuteehub.com/api/sht/";
export const LYRICS_BASE_URL = "https://lyrics.tuteehub.com/api/v3/lyr/";
export const CNEWS_BASE_URL = "https://careernews.tuteehub.com/api/";
export const CNEWS_URL = "https://careernews.tuteehub.com/";
export const BLOGS_BASE_URL = "https://blogs.tuteehub.com/api/v3/";
export const TUTORIALS_BASE_URL = "https://tutorials.tuteehub.com/api/v3/";
export const CORPORATES_BASE_URL = "https://corporates.tuteehub.com/api/v3/";
export const EDUCATORS_BASE_URL = "https://educators.tuteehub.com/api/v3/";
export const FORUM_BASE_URL = "https://forum.tuteehub.com/api/v3/";
export const FORUM_URL = "https://forum.tuteehub.com/forum/";
export const SHOWTALENT_BASE_URL = "https://showtalent.tuteehub.com/api/";
export const DASHBOARD_URL = "https://dashboard.tuteehub.com";
export const TOOLS_BASE_URL = "https://showtalent.tuteehub.com/api/";
export const SHOWTALENT_VIDEO_BASE_URL = "https://showtalent.tuteehub.com/videos/";
export const BLOGS_DETAIL_BASE_URL = "https://blogs.tuteehub.com/detail/";
export const FEEDS_DETAIL_BASE_URL = "https://feeds.tuteehub.com/feeds/";
export const CDN_BASE_URL = "https://tuteehub.nyc3.cdn.digitaloceanspaces.com/";
export const SHORT_TASK_ASSETS_BASE_URL = "https://nyc3.digitaloceanspaces.com/tuteehub/";
export const RAZOR_PAY_KEY = "rzp_live_veFAqT1crGAJKY";
// export const RAZOR_PAY_KEY ="rzp_live_dDFhBGPQxoBUYO"; 
export const ASK_ME_ANYTHING_KEY = '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';
export const RAZOR_PAY_KEY_TEST = "rzp_test_6p1rnBEaqr4ei9";
// export const RAZOR_PAY_KEY_TEST = "rzp_test_tK8sELS4Fk440U";
// export const RAZOR_PAY_KEY_TEST ="rzp_test_6p1rnBEaqr4ei9";
export const SHOWTALENT_APP_DEEP_LINK = "https://showtalent.page.link/6SuK";
export const SHOWTALENT_USER_DETAIL_BASE_LINK = "https://showtalent.tuteehub.com/user/";
export const USER_INFO_KEY = "h3i2j1k0l9m8n7o6pa2b9c8d7e6f5g4";
const SECRET_KEY = "743665d963c43b5b68a295a66c4013ab";
export const TINY_EDITOR_KEY = "4uc5cbuazhg6fon3oudspk03ud5chx2erdibkcsd6t5f7crj";
export const G_CAPTCHA_V3_SITE_KEY = "6LcrUxYqAAAAAFju2mB9HnDe3FLcgycn7XZqtmp6";
export const G_CAPTCHA_V2_SITE_KEY = "6Lde4JEnAAAAAJ5hjs477hXghOs5u18Oko4c6Drz";
export const GOOGLE_WEB_CLIENT_ID = "58514412537-2umj4o3hf73smdiat878bghepf641k1r.apps.googleusercontent.com";
export const CPA_LEAD_OFFERWALL_ID = '2768409';
export const CPX_SURVEY_APP_ID = '22384';
export const CPA_LEAD_OFFERWALL_URL = 'https://cpalead.com/dashboard/reports/campaign_json.php';
export const INVOICE_BASE_URL = 'https://www.tuteehub.com/credit-invoice/';
export const EXTRA_MSG = 'Syncing updated data.';
export const ERROR_SOMETHING_WENT_WRONG = 'Something went wrong!';
export const headerIcoUrls = {
  tuteehubMain: `https://tuteehub.com`,
  tuteehubFeeds: `https://feeds.tuteehub.com`,
  tuteehubVideo: `https://showtalent.tuteehub.com/`,
  tuteehubNews: `https://careernews.tuteehub.com`,
  tuteehubExams: `https://exams.tuteehub.com`,
  tuteehubFullForm: `https://fullforms.tuteehub.com`,
  tuteehubDictionary: `https://dictionary.tuteehub.com`,
  tuteehubQuotes: `https://quotes.tuteehub.com`,
  tuteehubLyrics: `https://lyrics.tuteehub.com`,
};
export const modulesUrls = {
  tuteehubMain: `https://tuteehub.com`,
  tuteehubFeeds: `https://feeds.tuteehub.com`,
  tuteehubVideo: `https://showtalent.tuteehub.com/`,
  tuteehubNews: `https://careernews.tuteehub.com`,
  tuteehubExams: `https://exams.tuteehub.com`,
  tuteehubFullForm: `https://fullforms.tuteehub.com`,
  tuteehubDictionary: `https://dictionary.tuteehub.com`,
  tuteehubQuotes: `https://quotes.tuteehub.com`,
  tuteehubLyrics: `https://lyrics.tuteehub.com`,
  tuteehubBlogs: `https://blogs.tuteehub.com`,
  tuteehubTutorial: `https://tutorials.tuteehub.com`,
  tuteehubCorporates: `https://corporates.tuteehub.com`,
  tuteehubEducators: `https://educators.tuteehub.com`,
  tuteehubForum: `https://forum.tuteehub.com`,
  tuteehubGigs: `https://gigs.tuteehub.com`,
  tuteehubQuestionBank: `https://questionbank.tuteehub.com`,
  tuteehubInterviewQuestions: `https://interviewquestions.tuteehub.com`,
  tuteehubWork: `https://work.tuteehub.com`
};

export const placeholderImages = {
  sprite: "/../public/sprite.png",
  userPicPlaceholder: "/../public/profile_placeholder.png",
  postImagePlaceholder: "/../public/post_image.jpg"
}

export const convertRating = (rating) => {
  return Number(rating) || 0.00;
}

export const calculateAverageRating = (ratings) => {

  if (ratings?.length === 0) {
    return 0.00; // Return 0 if the array is empty to avoid division by zero
  }

  const sum = ratings?.reduce((accumulator, currentValue) => {
    return accumulator + convertRating(currentValue?.rating);
  }, 0);
  const average = Math.floor(sum / ratings?.length);
  return average;
}

export const encryptData = (data) => {
  // console.log(data)
  const dataToEncrypt = data;
  const keyHex = "743665d963c43b5b68a295a66c4013ab";
  const ivHex = CryptoJS.lib.WordArray.random(16).toString();

  // Convert hex-encoded key and IV to WordArray
  let key = CryptoJS.enc.Hex.parse(keyHex);
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Encrypt using AES-128-CBC with PKCS7 padding
  const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let ecryptedData = encrypted.toString().replace(/\//g, "__23");

  // Get the ciphertext as a base64 string
  // let ciphertextBase64 = encrypted.toString();
  return ecryptedData + "__rxd" + iv;
}

export const parseQuestions = (questionTitle) => {
  const qTitle = questionTitle;
  const qObj = { __html: qTitle };
  return <div dangerouslySetInnerHTML={qObj} />

}

export const getCountryName = (countryCodes) => {
  const promises = countryCodes?.map(countryCode => {
    return fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data[0]?.name.common
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return "Country not found";
      });
  });

  return Promise.all(promises);
}

export const getCountryNameFromIp = async (ip) => {
  try {
    const response = await fetch(`http://ipinfo.io/${ip}/json`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // alert(JSON.stringify(data))
    return data.country;
  } catch (error) {
    console.error(error);
  }
}

export const scrollToFirstError = (errors) => {
  // alert(errors)
  const firstErrorField = Object.keys(errors)[0];
  if (firstErrorField) {
    const errorElement = document.getElementsByName(firstErrorField)[0];
    if (errorElement) {

      errorElement.scrollIntoView({ behavior: 'smooth' });
      errorElement.focus();
    }
    return; // Stop form submission if there are errors
  }
}
export const hasShortTaskAtBeginning = (str) => {
  // Regular expression to match "short-task" at the beginning of the path
  const regex = /^\/short-task\b/;
  return regex.test(str);
}
export const sidebarLinks = {
  careernews: 'https://careernews.tuteehub.com',
  questionbank: 'https://questionbank.tuteehub.com',
  interviewquestion: 'https://interviewquestions.tuteehub.com',
  fullform: 'https://fullforms.tuteehub.com',
  dictionary: 'https://dictionary.tuteehub.com',
  exams: 'https://exams.tuteehub.com',
  quotes: 'https://quotes.tuteehub.com',
  lyrics: 'https://lyrics.tuteehub.com'
}

export const convertLongNumberAbbreviated = (labelValue) => {
  // const value = new Intl.NumberFormat( 'en-US', {  minimumSignificantDigits: 3, maximumSignificantDigits: 3,
  // maximumFractionDigits: 3, minimumFractionDigits: 3,  notation: "compact" , compactDisplay: "short" }).format(number);
  // return value;
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
        : Math.abs(Number(labelValue));
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateRandomGradientColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    colors.push(`${getRandomColor()}, ${getRandomColor()}`);
  }
  return colors;
};

export const nFormatter = (num) => {
  if (Math.abs(num) > 999 && Math.abs(num) <= 100000) {
    return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
  } else if (Math.abs(num) > 100000 && Math.abs(num) <= 10000000) {
    return Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L';
  } else if (Math.abs(num) > 10000000 && Math.abs(num) <= 1000000000) {
    return Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1)) + 'M';
  } else if (Math.abs(num) > 1000000000) {
    return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B';
  } else {
    return Math.sign(num) * Math.abs(num);
  }
};

export const lightenColor = (color, percent) => {
  var num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
  return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}
export const getRandomSmoothColor = () => {
  // Generate a random hue value between 0 and 360
  var hue = Math.floor(Math.random() * 360);

  // Convert HSL to RGB
  var h = hue / 360;
  var s = 0.5; // Fixed saturation for smoothness
  var l = 0.5; // Fixed lightness for smoothness

  var c = (1 - Math.abs(2 * l - 1)) * s;
  var x = c * (1 - Math.abs((h * 6) % 2 - 1));
  var m = l - c / 2;

  var r, g, b;
  if (h < 1 / 6) {
    r = c;
    g = x;
    b = 0;
  } else if (h < 2 / 6) {
    r = x;
    g = c;
    b = 0;
  } else if (h < 3 / 6) {
    r = 0;
    g = c;
    b = x;
  } else if (h < 4 / 6) {
    r = 0;
    g = x;
    b = c;
  } else if (h < 5 / 6) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  // Convert RGB to hexadecimal
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Pad single-digit hex values with a leading zero
  r = r.length === 1 ? '0' + r : r;
  g = g.length === 1 ? '0' + g : g;
  b = b.length === 1 ? '0' + b : b;

  // Return the color in hexadecimal format
  return '#' + r + g + b;
}

export const yearSatra = () => {
  const currentYear = moment().year(); // Get the current year
  const nextYear = currentYear + 1; // Get the next year
  return `${currentYear} - ${nextYear}`;
}

export const convertDateFormat = (inputDate) => {
  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate);

  // Format the date to "D MMM YYYY" (1 JAN 1995) format
  const formattedDate = parsedDate.format('D MMM YYYY hh:mm A');

  return formattedDate;
}

export const convertDateFormatOnly = (inputDate) => {
  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate);

  // Format the date to "D MMM YYYY" (1 JAN 1995) format
  const formattedDate = parsedDate.format('D MMM YYYY');

  return formattedDate;
}

export const convertDateFormatForApi = (inputDate) => {
  // Check if the input date is a valid date
  if (!moment(inputDate, moment.ISO_8601).isValid()) {
    return null;
  }

  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate);

  // Format the date to "YYYY-MM-DD" format
  const formattedDate = parsedDate.format('YYYY-MM-DD');

  return formattedDate;
}

export const reverseDateFormat = (inputDate) => {
  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate);

  // Format the date to "D MMM YYYY" (1 JAN 1995) format
  const formattedDate = parsedDate.format('DD-MM-YYYY');

  return formattedDate;
}

export const calculateTotalAndSuccessRate = (
  userInprogressTask,
  userInReviewTask,
  userApproveTask,
  userRejectedTask,
  userExpiredTask,
  userDisputedTask,
  userReferredToAdminTask
) => {
  const totalTasks = userInReviewTask + userInprogressTask + userApproveTask + userRejectedTask + userExpiredTask + userDisputedTask + userReferredToAdminTask;
  const successRate = totalTasks > 0 ? (userApproveTask / totalTasks) * 100 : 0;
  return {
    totalTasks,
    successRate
  };
};

export const capitalizeTitle = (str) => {
  return str?.replace(/\b\w/g, char => char?.toUpperCase());
};
export const calculateExpireTime = (startTime, durationInMinutes) => {
  // Parse the start time as UTC using moment
  const startUTC = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');

  // Convert the UTC time to local time
  const startLocal = startUTC.local();

  // Add the duration to the start time in local time
  const expireTimeLocal = startLocal.clone().add(durationInMinutes, 'minutes');

  // Calculate the difference
  const duration = moment.duration(expireTimeLocal.diff(startLocal));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the expire time in local date and time in the desired format
  const expireTimeFullFormat = expireTimeLocal.format('D MMM YYYY hh:mm:ss A');




  // Get the current local time
  const currentLocalTime = moment().local();
  let diff = null;
  // Calculate the remaining time if the expire time hasn't passed yet
  if (currentLocalTime.isBefore(expireTimeLocal)) {
    const duration = moment.duration(expireTimeLocal.diff(currentLocalTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    diff = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  } else {
    diff = '00:00:00'; // Expire time has passed
  }
  return {
    fullFormat: expireTimeFullFormat,
    difference: diff
  };
};

export const calculateExpireTimeNew = (expireTime) => {
  // Parse the start time as UTC using moment
  const expireTimeT = moment.utc(expireTime, 'YYYY-MM-DD HH:mm:ss');

  // Convert the UTC time to local time
  const expLocal = expireTimeT.local();




  // Get the current local time
  const currentLocalTime = moment().local();
  let diff = null;
  // Calculate the remaining time if the expire time hasn't passed yet
  if (currentLocalTime.isBefore(expLocal)) {
    const duration = moment.duration(expLocal.diff(currentLocalTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    diff = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  } else {
    diff = '00:00:00'; // Expire time has passed
  }
  return {
    difference: diff
  };
};

export const convertToLocalTime = (st) => {
  // Parse the start time as UTC using moment.utc
  const parsedDate = moment.utc(st, 'YYYY-MM-DD HH:mm:ss');

  // Convert to local time
  const localDate = parsedDate.local();

  // console.log("Original UTC Date:", st);
  // console.log("Local Date:", localDate.format('DD/MM/YYYY hh:mm A'));

  return localDate.format('DD/MM/YYYY hh:mm A');
};

// export const calculateTimeInterval = (startTime, endTime) => {
//   // Check if endTime is provided
//   if (!endTime) {
//     return {
//       difference: "0 Mins"
//     };
//   }

//   // Parse the start and end times as UTC using moment
//   const startUTC = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');
//   const endUTC = moment.utc(endTime, 'YYYY-MM-DD HH:mm:ss');

//   // Calculate the difference in minutes
//   const duration = moment.duration(endUTC.diff(startUTC));
//   const minutes = duration.asMinutes();

//   // Return the difference in a readable format
//   return {
//     difference: `${Math.floor(minutes)} Mins`
//   };
// };

export const calculateTimeInterval = (startTime, endTime) => {
  // Check if endTime is provided
  if (!endTime) {
    return {
      difference: "0 Mins"
    };
  }

  // Parse the start and end times as UTC using moment
  const startUTC = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');
  const endUTC = moment.utc(endTime, 'YYYY-MM-DD HH:mm:ss');

  // Calculate the difference in seconds
  const duration = moment.duration(endUTC.diff(startUTC));
  const seconds = duration.asSeconds();

  // Convert the difference to minutes
  const minutes = seconds / 60;
  // console.log(seconds)
  // Return the difference in a readable format
  return {
    difference: `${minutes.toFixed(2)} Mins`
  };
};

export const getParams = (search, paramNames) => {
  const queryParams = new URLSearchParams(search);
  const params = {};
  console.log(queryParams)
  // Iterate over each parameter name and retrieve its value from the URL search string
  paramNames.forEach(paramName => {
    params[paramName] = queryParams.get(paramName);
  });

  return params;
};

// export const formatTime = item => {
//     let ms = moment().diff(moment(item.created_at));
//     let d = moment.duration(ms);
//     let time = null;
//     if (d.asHours() > 24) {
//       time = `${d.asDays().toFixed(0)} days ago`;
//     } else if (d.asHours() == 24) {
//       time = `1 day ago`;
//     } else if (d.asHours() < 24 && d.asHours() >= 1) {
//       time = `${d.hours().toFixed(0)} hours ago`;
//     } else if (d.asMinutes() === 60) {
//       time = `1 hour ago`;
//     } else if (d.asMinutes() < 60 && d.asMinutes() >= 1) {
//       time = `${d.minutes().toFixed(0)} minutes ago`;
//     } else {
//       time = `Just now`;
//     }
//     return time;
//   };

export const moduleNames = {
  qb: "question-Bank",
  ff: "fullforms",
  iq: "interview-questions",
  dic: "dictionary",
  gig: "gigs",
  ex: "exams",
  bl: "blogs",
  tut: "tutorials",
  fm: "forum",
  cn: "career-news",
  fd: "feeds",
  corp: "corporates",
  edu: "educators",
  lyr: "lyrics",
  qt: "quotes",
};

export const moduleSearchType = [
  { label: 'Question Bank', value: 'qb', url: 'question-Bank', placeholder: 'Search Solved Questions Across Any Subject Or Topic...' },
  { label: 'Fullforms', value: 'ff', url: 'fullforms', placeholder: 'Search Full Forms Across Any Categories...' },
  { label: 'Interview Questions', value: 'iq', url: 'interview-questions', placeholder: 'Find Answers to Job Interview Questions...' },
  { label: 'Dictionary', value: 'dic', url: 'dictionary', placeholder: 'Search Dictionary/Word Meaning Across Any Categories...' },
  { label: 'Gigs', value: 'gig', url: 'gigs', placeholder: "What's on your mind?" },
  { label: 'Exams', value: 'ex', url: 'exams', placeholder: "Search Exams Across Any Subject Or Topic ..." },
  { label: 'Blogs', value: 'bl', url: 'blogs', placeholder: "Search Blogs" },
  { label: 'Tutorials', value: 'tut', url: 'tutorials', placeholder: "Search Tutorial" },
  { label: 'Forum', value: 'fm', url: 'forum', placeholder: "Search Questions" },
  { label: 'Career News', value: 'cn', url: 'career-news', placeholder: 'Search for Top Exams, Job Alerts, Syllabus, Pattern, Admit Card, Result News of ' + new Date().getFullYear() },
  { label: 'Feeds', value: 'fd', url: 'feeds', placeholder: "Search Feeds..." },
  { label: 'Corporates', value: 'corp', url: 'corporates', placeholder: "Search Companies Across Any Categories ..." },
  { label: 'Educators', value: 'edu', url: 'educators', placeholder: "Search Educators Across Any Categories ..." },
  { label: 'Lyrics', value: 'lyr', url: 'lyrics', placeholder: "Search Lyrics By Artist, Lyricist Or Album ..." },
  { label: 'Quotes', value: 'qt', url: 'quotes', placeholder: "Search Quotes on any Topic or by any Author ..." }
];

export const moduleTypes = [
  { label: 'TuteeHUB', value: 'thub' },
  { label: 'Question Bank', value: 'qb' },
  { label: 'Fullforms', value: 'ff'},
  { label: 'Interview Questions', value: 'iq' },
  { label: 'Dictionary', value: 'dic'},
  { label: 'Gigs', value: 'gig'},
  { label: 'Exams', value: 'ex' },
  { label: 'Blogs', value: 'bl'},
  { label: 'Tutorials', value: 'tut'},
  { label: 'Forum', value: 'fm'},
  { label: 'Career News', value: 'cn' },
  { label: 'Feeds', value: 'fd', url: 'feeds'},
  { label: 'Corporates', value: 'corp', url: 'corporates'},
  { label: 'Educators', value: 'edu' },
  { label: 'Lyrics', value: 'lyr' },
  { label: 'Quotes', value: 'qt'},
  { label: 'Tutoring', value: 'tut' },
  { label: 'Show Talent', value: 'st' },
  { label: 'Tools', value: 'tools' },
  { label: 'Courses', value: 'courses' },
  { label: 'Work', value: 'work' }

];

export const formatTime = (item) => {
  const now = moment();
  const createdAt = moment(item.created_at);
  const ms = now.diff(createdAt);
  const d = moment.duration(ms);

  let time = null;

  if (d.asYears() >= 1) {
    time = `${Math.floor(d.asYears())} year${Math.floor(d.asYears()) > 1 ? 's' : ''} ago`;
  } else if (d.asMonths() >= 1) {
    time = `${Math.floor(d.asMonths())} month${Math.floor(d.asMonths()) > 1 ? 's' : ''} ago`;
  } else if (d.asDays() >= 1) {
    time = `${Math.floor(d.asDays())} day${Math.floor(d.asDays()) > 1 ? 's' : ''} ago`;
  } else if (d.asHours() >= 1) {
    time = `${Math.floor(d.asHours())} hour${Math.floor(d.asHours()) > 1 ? 's' : ''} ago`;
  } else if (d.asMinutes() >= 1) {
    time = `${Math.floor(d.asMinutes())} minute${Math.floor(d.asMinutes()) > 1 ? 's' : ''} ago`;
  } else {
    time = 'Just now';
  }
  return time;
};


export const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Utility function to strip HTML tags
export const stripHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
};

export const formatDate = (dateToFormat) => {
  const utcDate = moment.utc(dateToFormat);
  const formattedDate = utcDate.local().format('DD/MM/YYYY hh:mm A');
  // const formattedDate = moment(dateToFormat).format('DD/MM/YYYY hh:mm A');
  return formattedDate;
}
export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(email.toLowerCase());
};

export const validatePhone = phone => {
  const reg = /^(\+\d{1,3}[- ]?)?\d{9,15}$/;
  return reg.test(phone);
};

export const isEmptyString = string => {
  return !/\S/.test(string);
};

export const apiToken = async tuteehubId => {
  // console.log('tuteehub_id ..........................', tuteehubId);
  let formdata = new FormData();
  formdata.append('tuteehubId', tuteehubId);
  const tokenData = await PostData('user/api-token', 'normal', { tuteehubId: tuteehubId });

  // console.log('token of user ...............', tokenData)
  return tokenData;
}

export const fetchUserInfo = async (userId, token, setUser, setFinance=null, user, setLoading = null, setTotalCredits = null, socialLoggedInImage = null) => {
  const res = await getUserInfo(userId, token);

  if (res.success) {
    setUser({ ...user, email_verified:res.data?.email_verified, access_token: token, can_user_purchase: res?.data?.can_user_purchase, address: res?.data?.address, country: res?.data?.country, state: res?.data?.state, city: res?.data?.city, postal_code: res?.data?.postal_code, phone: res?.data?.phone, country_code: res?.data?.country_code, name: res?.data?.name, profile_image: socialLoggedInImage ? socialLoggedInImage : (res?.data?.profileImage) ? CDN_BASE_URL + res?.data?.profileImage : null, credits: res.data?.credits })

    // localStorage.setItem('userDetails', JSON.stringify({ ...user, access_token: token, phone: res?.data?.phone, country_code: res?.data?.country_code, name: res?.data?.name, profile_image: (res?.data?.profileImage) ? CDN_BASE_URL + res?.data?.profileImage : null }))
    // localStorage.setItem('____fi', JSON.stringify({ __crdt: res.data?.credits }))
    setFinance({ __crdt: res.data?.credits });

    if (setTotalCredits)
      setTotalCredits(res.data?.credits);
    // setUser({...user, credits:res?.data?.credits})
    // localStorage.setItem('userDetails',JSON.stringify({...user, credits:res?.data?.credits}))
    // localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
    // setFinance({__crdt:res.data?.credits}) 
  }
}

export const emailNotVerified = (user, setIsEmailVerifyCover) =>{
   if(user?.email_verified==0){
      toast.error('Please verify your email');
      setTimeout(() => { setIsEmailVerifyCover(true) }, 200);
      return false;
  }
  return true;
}

export const closeModal = (modalId) => {
  let modal = document.getElementById(modalId);
  modal.classList.remove("show");
  modal.setAttribute("aria-hidden", "true");
  modal.setAttribute("style", "display: none");
  const modalBackdrops =
    document.getElementsByClassName("modal-backdrop");
  document.body.style.overflowY = "auto";
  document.body.removeChild(modalBackdrops[0]);


}

// export const  slugToNormal = (slug) => {
//   // Split the slug into separate words
//   const words = slug.split('-');

//   // Capitalize the first letter of each word
//   const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

//   // Join the words back together
//   const normalValue = capitalizedWords.join(' ');

//   return normalValue;
// }

export const slugToNormal = (slug) => {

  if (!slug) {
    return ''; // Return an empty string if the slug is null, undefined, or empty
  }

  // Split the slug into separate words
  const words = slug?.split('-');

  // Remove trailing numbers from the last word
  const lastWordIndex = words?.length - 1;
  words[lastWordIndex] = words[lastWordIndex]?.replace(/\d+$/, '');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));

  // Join the words back together
  const normalValue = capitalizedWords.join(' ');

  return normalValue;
};

export const slugToNormalBreadcrumb = (slug) => {

  if (!slug) {
    return ''; // Return an empty string if the slug is null, undefined, or empty
  }

  // Split the slug into separate words
  const words = slug?.split('-');
if(words[0]=='')
{
return 'All'; 
}
  // Remove trailing numbers from the last word
  const lastWordIndex = words?.length - 1;
  words[lastWordIndex] = words[lastWordIndex]?.replace(/\d+$/, '');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));

  // Join the words back together
  const normalValue = capitalizedWords.join(' ');

  return normalValue;
};
export const stripTags = (strData) => {
  return strData?.replace(/<\/?[^>]+(>|$)/g, "");
}

export const limitSubtitle = (description, limit = 60, end = '...') => {
  return description?.length > limit ? description.substring(0, limit) + end : description;
}
export const slugify = (title) => {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"
  const newText = (title?.trim())?.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    ?.toString()                     // Cast to string
    ?.toLowerCase()                  // Convert the string to lowercase letters
    ?.trim()                         // Remove whitespace from both sides of a string
    ?.replace(/\s+/g, '-')           // Replace spaces with -
    ?.replace(/&/g, '-y-')           // Replace & with 'and'
    ?.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    ?.replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

export const getImageHeight = (url, callback) => {
  var img = new Image();
  img.src = url;
  img.onload = function () { callback(this.width, this.height); }
}


export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches;
};

const SALT_LENGTH = 6;
const generateSalt = () => {
  return CryptoJS.lib.WordArray.random(SALT_LENGTH).toString(CryptoJS.enc.Base64).substring(0, SALT_LENGTH);
};

// Encrypt data
export const encryptLocalData = (data) => {
  try {
    const salt = generateSalt();
    const key = SECRET_KEY + salt; // Salt incorporated into the key
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    const encoded = salt + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(ciphertext)); // Combine salt + ciphertext in Base64
    return encoded;
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
};

// utils.js or a similar utility file
export const plularliseData = (title) => {
  if (!title) return '';
  const addSpaceAfterSub = title.replace(/sub([a-zA-Z]+)/g, (match, p1) => {
    
    return !['subject', 'project'].includes(title.toLowerCase()) ? `sub ${p1}` : `sub${p1}`
});
// });
// console.log(addSpaceAfterSub)
  // Step 2: Pluralize words based on the last character
  const pluralizeWords = addSpaceAfterSub.replace(/y$/, (match) => 
    !/[aeiou]y$/.test(title) ? 'ies' : 's'  // Consonant + y -> ies, else s
  );

  // Step 3: Add "s" if the word doesn't end with "s", "x", "z", or "ch"
  const addSIfNeeded = pluralizeWords.replace(/(?!sub)[a-zA-Z]+$/, (match) => 
    !match.endsWith('s') && !match.endsWith('x') && !match.endsWith('z') && !match.endsWith('ch') 
    ? match + 's'  // Add "s" if not ending in s, x, z, or ch
    : match
  );

  return addSIfNeeded;
};


// Decrypt data
export const decryptLocalData = (encryptedData) => {
  try {
    const salt = encryptedData.substring(0, SALT_LENGTH); // Extract the salt
    const ciphertext = CryptoJS.enc.Base64.parse(encryptedData.substring(SALT_LENGTH)).toString(CryptoJS.enc.Utf8); // Decode Base64
    const key = SECRET_KEY + salt; // Recreate the key with salt
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    if (!decrypted) throw new Error("Decryption failed");
    return JSON.parse(decrypted);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};


export const viewRecentVisits = async (token) =>{
  const resRecentVisits = await getRecentVisits ({ token: token });
  if(resRecentVisits?.success && Array.isArray(resRecentVisits?.data)){
    const isUrlVisited = resRecentVisits?.data.some(
      (visit) => visit?.recent_visits === window.location.href
    );
    if (!isUrlVisited) {
      await handleRecentVisits(token);
    }
  }
} 

export const handleRecentVisits = async (token) =>{
const resRecentVisits = await saveRecentVisits({ token: token, recent_visits:window.location.href });
if (resRecentVisits?.success) {
console.log("Recent visit saved successfully");
} else {
console.error("Failed to save recent visit");
}
}

// ...existing code...

export const convertSlugToTitle = (url) => {
  try {
    const slug = url.split('/').pop();
    const cleanSlug = slug.replace(/-\d+$/, '');
    const words = cleanSlug.split('-');
    const title = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
      
    return title;
  } catch (error) {
    console.error('Error converting slug to title:', error);
    return url;
  }
}

export const capitalizeLabel = (label) => {
  return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
};

