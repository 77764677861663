import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

function TagSelect({
  options = [],
  handleCustomSelectOnChange,
  defaultOption = [],
  isSelectLoading,
  placeholder = "Select or type to add tags...",
  isClearable = true,
  isMultiple = true,
  setFieldValue = () => {},
  type
}) {
  const [tagOptions, setTagOptions] = useState(options);
  const [selectedTags, setSelectedTags] = useState(defaultOption);
  const [inputValue, setInputValue] = useState("");

  const handleTagChange = (selected) => {
    setSelectedTags(selected);
    // handleCustomSelectOnChange(isMultiple ? selected : selected?.value, "tags");
    handleCustomSelectOnChange(selected, type, selected, setFieldValue)
  };

  const handleTagCreate = (input) => {
    if (!input.trim()) return;

    const newTag = { value: input, label: input };
    console.log(newTag)
    const updatedTags = isMultiple ? [...selectedTags, newTag] : [newTag];

    setTagOptions((prevTags) => [...prevTags, newTag]); // Add to options
    setSelectedTags(updatedTags); // Auto-select new tag
    setInputValue(""); // Clear input
    handleCustomSelectOnChange(isMultiple?updatedTags:newTag?.value, type,newTag?.label, setFieldValue)
    // handleCustomSelectOnChange(isMultiple ? updatedTags : newTag.value, "tags");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault(); // Prevent form submission
      handleTagCreate(inputValue);
    }
  };

  return (
    <CreatableSelect
      options={options}
      isMulti={isMultiple}
      className="bg-gray-50"
      value={selectedTags}
      onChange={handleTagChange}
      onCreateOption={handleTagCreate}
    //   onInputChange={(value) => setInputValue(value)}
      onKeyDown={handleKeyDown} // Handle Enter key press
      isSearchable
      isLoading={isSelectLoading}
      isClearable={isClearable}
      placeholder={placeholder}
    />
  );
}

export default TagSelect;
