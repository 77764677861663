import { TUTORIALS_BASE_URL } from "../../utils/utils";

export const getTutorialCategory = (params) => {
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/tags?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getTutoirialListing = (params) => {
    const { pageNo, tagSlug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/tags/${tagSlug}?page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const postTutorial = (params, formData) => {
    const { title, tag, token, description, type, id, about, faq } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    // let formData = new FormData();
    formData.append('title', title)
    formData.append('description', description);
    formData.append('about', about);
    tag.forEach((t, index) => {
        formData.append(`tag[${index}]`, t.label);
    }); 
    formData.append('formtype', type);
    formData.append('data_structure', 'd');
    if(id)
    formData.append('id', id); 
    // if(image)
    //     formData.append('image', image);

    if(faq){
        faq?.forEach((item, index) => {
            formData.append(`question[${index}]`, item.question);
            formData.append(`answer[${index}]`, item.answer);
        });
    }
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    let fUrl = type=="post"?`${TUTORIALS_BASE_URL}tutorials/add`:`${TUTORIALS_BASE_URL}tutorials/add`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const postEditChapter = (params) => {
    const { title, description, token, tutorialId, parentId, ordering, id, faq } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append('title', title)
    formData.append('description', description);
    formData.append('tutorial_id', tutorialId);
    formData.append('data_structure', 'd');
    formData.append('parent_id', parentId);
    formData.append('ordering', ordering);
    if(faq){
        faq?.forEach((item, index) => {
            formData.append(`question[${index}]`, item.question);
            formData.append(`answer[${index}]`, item.answer);
        });
    }
    if(id)
    formData.append('id', id); 

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    let fUrl = `${TUTORIALS_BASE_URL}tutorials/chapter`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getMyTutoirialsListing = (params) => {
    const { pageNo, token, status } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/my-tutorials?status=${status}&page=${pageNo}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const deleteTutorial = (params) => {
    const { tutorialId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append('tutorial_id', tutorialId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/delete`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getTutorialDetails = (params) => {
    const { tutorialId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/detail/${tutorialId}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const getTutorialChaptersList = (params) => {
    const { tutorialId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/detail/${tutorialId}/chapters`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getTutorialDetailByChapter = (params) => {
    const { tutorialId, chapterId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/detail/${tutorialId}/chapters/${chapterId}`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const likeUnlikeDislike = (params) => {
    const { tutorialId, voteType, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    // formData.append('user_id', user_id);
    formData.append('response', voteType)
    formData.append('tutorial_id', tutorialId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${TUTORIALS_BASE_URL}tutorials/like-dislike`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const tutorialCommentAndReply = (params) => { 
    const { tutorialId, comment, commentId, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('tutorial_id', tutorialId);
    formData.append('comment', comment);
    formData.append('comment_id', commentId || 0);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${TUTORIALS_BASE_URL}tutorials/post-comment`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const bookMarkTutorial = (params) => { 
    const { tutorialId, favourite, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('tutorial_id', tutorialId);
    formData.append('action', favourite);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${TUTORIALS_BASE_URL}tutorials/add-to-fav`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const getFavouritesTutorials = (params) => { 
    const { pageNo, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${TUTORIALS_BASE_URL}tutorials/get-fav-tutorial?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getSearchedData = (params) => { 
    const { pageNo, keyword } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
      method: "GET",
      headers: myHeaders
    };  
    return fetch(`${TUTORIALS_BASE_URL}tutorials/search?page=${pageNo}&title=${keyword}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const writeReviewTutorial = (params) => {
    const { tutorialId, reviews, rating, token } = params;
    // alert(JSON.stringify(params))
    // return;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("tutorial_id", tutorialId);
    formData.append("description", reviews);
    formData.append("rating", rating);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${TUTORIALS_BASE_URL}tutorials/rating`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
  };
  
  export const reportAgainstTutorial = (params) => {
    const { tutorialId, report, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("tutorial_id", tutorialId);
    formData.append("report_message", report);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${TUTORIALS_BASE_URL}tutorials/report`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
  };