import { isArray } from "lodash";
import { Profiler, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

import ReceiptIcon from '@material-ui/icons/Receipt';
import { getAccountDetail, getCoinPurchaseHistoryList, getConfigs, getCreditHistory, getFavQuestions, getGigsCreditStats, getLeaderBoardList, getTransactionHistory, getUserToken, getWithdrawalList, gigsTransactions, onRedeemCodeWithTH, onRedeemQuizCredits, sendWithdrawalRequest, withdrawalConfig } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { useAuth } from "../../../Context/AuthContext";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Tab, Tabs } from "react-bootstrap";
import { INVOICE_BASE_URL, SHOWTALENT_USER_DETAIL_BASE_LINK, USER_INFO_KEY, encryptData, fetchUserInfo, formatDate, generateRandomColor, nFormatter, slugify, viewRecentVisits } from "../../../utils/utils";
import notFound from '../../../assets/images/not_found_new.png';
import crown from '../../../assets/images/crown.png';
import leaderPic from '../../../assets/images/profile.jpg';
import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from "react-icons/fa";

function Finance() {
    const toDatepickerRef = useRef(null);
    const fromDatepickerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [creditHistoryList, setCreditHistoryList] = useState([]);
    const [purchaseCreditHistoryList, setPurchaseCreditHistoryList] = useState([]);
    const [leaderBoardList, setLeaderBoardList] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [creditToWithdrawal, setCreditToWithdrawal] = useState();
    const [showHideWithdrawal, setShowHideWithdrawal] = useState(false);
    const [filteredCreditHistory, setFilteredCreditHistory] = useState([]);
    const [filteredGigsCreditHistory, setFilteredGigsCreditHistory] = useState([]);
    const [gigsCreditHistory, setGigsCreditHistory] = useState();
    const [transactionHistoryList, setTransactionHistoryList] = useState([]);
    const [withdrawalRequestList, setWithdrawalRequestList] = useState([]);
    const [coinPurchaseHistoryList, setCoinPurchaseHistoryList] = useState([]);
    const [filteredCoinPurchaseHistoryList, setFilteredCoinPurchaseHistoryList] = useState([]);
    const [failedTransactions, setFailedTransactions] = useState();
    const [successTransactios, setSuccessTransactions] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [hashMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    const { qToken, iQToken, ffToken, dcToken, user, setUser, finance, setFinance, setQtoken, globalToken } = useAuth();
    const [choosenModule, setChoosenModule] = useState('tuteehub');
    const { moduleName } = useParams();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [totalCredits, setTotalCredits] = useState();
    const [minWithdrawalLimit, setMinWithdrawalLimit]= useState(0);
    const [amountByCredit, setAmountByCredit] = useState(1);
    const [currencyType, setCurrencyType] = useState('');
    const [showAnimatedCareditMessage, setShowAnimatedCareditMessage] = useState(false);
    const [redeemCode, setRedeemCode] = useState();
    const [gigsCreditsStats, setGigsCreditsStats] = useState({});
    const [redeemedCredits, setRedeemedCredits] = useState(0);
    const location = useLocation();
    const currentRoute = location.pathname;
    const navigation = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const [bankDetails, setBankDetails] = useState();
    const [withdrawalConfiguration, setWithdrawalConfiguration] = useState(null)
    const modules = [
        {
            "name": "All",
            "value": "tuteehub"
        },
        {
            "name": "Showtalent",
            "value": "showtalent"
        }, {
            "name": "Questionbank",
            "value": "questionbank"
        }, {
            "name": "Dictionary",
            "value": "dictionary"
        }, {
            "name": "Exams",
            "value": "exams"
        }, {
            "name": "Fullform",
            "value": "fullform"
        }, {
            "name": "Interview Question",
            "value": "interviewq"
        }, {
            "name": "Careernews",
            "value": "careernews"
        }, {
            "name": "Courses",
            "value": "courses"
        }, {
            "name": "Work",
            "value": "work"
        }, {
            "name": "Forum",
            "value": "forum"
        }, {
            "name": "Tutorials",
            "value": "tutorials"
        }, {
            "name": "Blogs",
            "value": "blogs"
        }, {
            "name": "Educators",
            "value": "educators"
        }, {
            "name": "Corporates",
            "value": "corporates"
        }, {
            "name": "Tools",
            "value": "tools"
        }, {
            "name": "Gigs",
            "value": "gigs"
        }, {
            "name": "Feeds",
            "value": "feeds"
        }, {
            "name": "Lyrics",
            "value": "lyrics"
        }, {
            "name": "Quotes",
            "value": "quotes"
        }]

    const withdrawalStatus = [
        {
            label:'Pending',
            value:1
        },{
            label:'Processing',
            value:2
        },{
            label:'Completed',
            value:3
        },{
            label:'Rejected',
            value:4
        },{
            label:'Failed',
            value:5
        }
    ]
    const handleConfig = async () =>{
        const res = await getConfigs();
        
        if(res?.status==1){
            setMinWithdrawalLimit(res?.data?.minimum_withdrawal_amount);
            setAmountByCredit(res?.data?.coins_per_unit);
            setCurrencyType(res?.data?.unit_name);
        }
       
    }
    const handleModuleChange = (e) => {
        setLoading(true)
        setToDate(moment().format('YYYY-MM-DD'))
        let oneYearAgo = moment().subtract(365, 'days');
        setFromDate(oneYearAgo.format('YYYY-MM-DD'));
        setChoosenModule(e.target.value);
        // fetchCreditHistory(e.target.value);
    }

    const handleGigsCreditsStats = async () =>{
        // let gigToken = JSON.parse(localStorage.getItem('gigsToken')).token;
        const gigsCreditsStatsRes = await getGigsCreditStats({token:globalToken})
        if(gigsCreditsStatsRes?.success){
            setGigsCreditsStats(gigsCreditsStatsRes?.data);
        }
        setLoading(false)
    }

    const handleWithdrawalConfig = async () =>{
        const wConfig = await withdrawalConfig()
        if(wConfig?.success){
            setWithdrawalConfiguration(wConfig?.data);
        }
        setLoading(false)
    }
    useEffect(()=>{
        if(choosenModule=='gigs'){
            fetchGigsCreditHistory(1);
            handleGigsCreditsStats();
        }else{
            // alert(choosenModule)
            fetchCreditHistory(choosenModule);
        }
    },[choosenModule])

    const purchaseCreditHistory = async() => {
        const res = await getCreditHistory(
            'tuteehub',
            user?.tuteehub_id,
            1
        );
        setPurchaseCreditHistoryList(res?.data?.data);
    }

    const fetchCreditHistory = async (moduleName) => {
        // alert(moduleName)
        if(moduleName!=='gigs'){
            const res = await getCreditHistory(
                moduleName,
                user?.tuteehub_id,
                1
            );
            setFilteredCreditHistory(res?.data?.data)
            setCreditHistoryList(res?.data?.data);
            // setOnLoadDate()

        }
        setLoading(false)
       
    }

    const fetchGigsCreditHistory = async (page) => {
        // let gigToken = JSON.parse(localStorage.getItem('gigsToken')).token;
        const res = await gigsTransactions({currentPage:page, token:globalToken});
        
        if(res?.success){
            setGigsCreditHistory(res?.data);
            
            if(page!==res?.data?.current_page){
                // console.log('bye')
                setFilteredGigsCreditHistory([...filteredGigsCreditHistory,...res?.data?.data])
            }else{
                // console.log(choosenModule)
                setFilteredGigsCreditHistory(res?.data?.data)
            }           
        }
        setLoading(false)
    }

    // const fetchMoreGigsCreditHistory = async () => {
    //     let nxtPage = pageNumber + 1;
    //     fetchGigsCreditHistory(nxtPage);
        
    // }

    const fetchTransactionHistory = async () => {
        const res = await getTransactionHistory(user?.tuteehub_id);
      
        setTransactionHistoryList(res?.data?.data);
        setFailedTransactions((res?.data?.data?.filter(d => d.status == 'failed'))?.length)
        setSuccessTransactions((res?.data?.data?.filter(d => d.status == 'success'))?.length)
    }


    // const totalCredits = creditHistoryList?.reduce((sum, creditHistory) => {
    //     return sum + (creditHistory?.credits || 0);
    // }, 0);

    const totalTransactions = transactionHistoryList?.reduce((sum, transactionHistory) => {
        return sum + (transactionHistory?.amount || 0);
    }, 0);
    // const totalTransactions = 10;

    useEffect(() => {
        handleWithdrawalConfig();
        handleConfig();
        const crdt = JSON.parse(localStorage.getItem('____fi'))?.__crdt;
        setTotalCredits(crdt)
        // fetchQbToken();
        // getUserInfo(user?.tuteehub_id)
        fetchCreditHistory(choosenModule);
        fetchTransactionHistory();
        fetchLeaderBoardList('showtalent')
        fetchWithdrawalRequestList()
        fetchCoinPurchaseHistoryList();
        viewRecentVisits(globalToken);
        
    }, [])



    useEffect(() => {
        if(currentRoute=='/credit-purchase-history'){
            purchaseCreditHistory()
        }
        if(currentRoute =='/withdrawal'){
            fetchBankDetails();
        }
        setOnLoadDate()
    }, []);

    useEffect(() => {
        const startDate = moment(fromDate, 'YYYY-MM-DD').startOf('day');
        const endDate = moment(toDate, 'YYYY-MM-DD').endOf('day');
        
        if(choosenModule=='gigs'){
            
            let filteredHistories = gigsCreditHistory?.data?.filter((history) => {
                const historyDate = moment(history.created_at);
                // const historyDate = moment(moment(history.created_at).format('YYYY-MM-DD')).startOf('day');
                // console.log(historyDate)
                // console.log(historyDate.isBetween(startDate, endDate, null, '[]'))
               return historyDate.isBetween(startDate, endDate, null, '[]');
            });
            
            setFilteredGigsCreditHistory(filteredHistories);
        }else{
            let filteredHistories = creditHistoryList?.filter((history) => {
                const historyDate = moment(history.created_at);
                // const historyDate = moment(moment(history.created_at).format('YYYY-MM-DD')).startOf('day');
                // console.log(historyDate)
                // console.log(historyDate.isBetween(startDate, endDate, null, '[]'))
               return historyDate.isBetween(startDate, endDate, null, '[]');
            });
            setFilteredCreditHistory(filteredHistories);

            let filteredCoinPurchaseHistories = coinPurchaseHistoryList?.filter((cHistory) => {
                const cHistoryDate = moment(cHistory.created_at);
                // const historyDate = moment(moment(history.created_at).format('YYYY-MM-DD')).startOf('day');
                // console.log(historyDate)
                // console.log(historyDate.isBetween(startDate, endDate, null, '[]'))
               return cHistoryDate.isBetween(startDate, endDate, null, '[]');
            });
            
            setFilteredCoinPurchaseHistoryList(filteredCoinPurchaseHistories);

        }
        
        // setFilteredGigsCreditHistory(filteredHistories);
    }, [fromDate, toDate]);


    const setOnLoadDate = () => {
        let currentDate = moment();
        let oneYearAgo = moment().subtract(365, 'days');
            setFromDate(oneYearAgo.format('YYYY-MM-DD'));
            setToDate(currentDate.format('YYYY-MM-DD'));
        // }
        // else{
        //     console.log(fromDate, toDate)
        // }    

        const filteredHistories = creditHistoryList?.filter((history) => {
            const historyDate = moment(history.created_at);
            
            return historyDate.isBetween(oneYearAgo, currentDate, null, '[]');
        });
        // setFilteredCreditHistory([])
        // console.log(filteredHistories)
        setFilteredCreditHistory(filteredHistories);
        // setCreditHistoryList(filteredHistories);
        // console.log(filteredHistories)
        // setFilteredGigsCreditHistory(filteredHistories);
    }

    // const filterByDate = () =>{
    //     const filteredHistories = creditHistoryList.filter((history) => {
    //         const historyDate = moment(history.created_at);
    //         const fDate= moment(fromDate);
    //         const tDate= moment(toDate);
            
    //         return historyDate.isBetween(fDate, tDate, null, '[]');
    //     });
    //     setFilteredCreditHistory(filteredHistories);
    // }
    const fetchLeaderBoardList = async(moduleName) =>{
        setLoading(true)
        const res = await getLeaderBoardList(moduleName);
        // console.log(res.data)
        setLeaderBoardList(res?.data);
        setLoading(false)
    }

    const getUserInfo = async(userId) =>{
        // const res = await getUserInfo(userId, globalToken);
        // setFinance({__crdt:res?.data?.credits})
        fetchUserInfo(userId, globalToken, setUser, setFinance, user, null, setTotalCredits)
        // setTotalCredits(res?.data?.credits)
    }

    const fetchWithdrawalRequestList = async() =>{
        const res = await getWithdrawalList(user?.tuteehub_id);
        console.log('dd', res)
        setWithdrawalRequestList(res?.data?.data)
        // return res.data.name;
    }
    
    // const setStorage = (name, res) => {
    //     localStorage.setItem(
    //       name,
    //       JSON.stringify(
    //         {
    //           token: res?.data?.token,
    //           id: res?.data?.id,
    //         } || null
    //       )
    //     );
    //   };

    // fetch questionbank token
//     const fetchQbToken = async () =>{
//         const qbToken = localStorage.getItem('___ctkn');
//         if(qbToken){
//             setQtoken(qbToken) 
//         }else{
//             const res = await getUserToken(user?.tuteehub_id, 'question-Bank');     
//                 if (res?.success) {
//                     setQtoken(res?.data?.token);
//                 localStorage.setItem("___ctkn", res?.data?.token);                  
//             }
//         }
      
       

// }




    const fetchCoinPurchaseHistoryList = async() =>{
        const res = await getCoinPurchaseHistoryList(user?.tuteehub_id);
        // console.log(res)
        setCoinPurchaseHistoryList(res?.data?.data);
        setFilteredCoinPurchaseHistoryList(res?.data?.data);
        // return res.data.name;
    }

    // function to claim credits
    const handleClaimCredits = async () => {
        const endsWithTH = (code) => {
            return code.toUpperCase().endsWith('TH');
        };

        setBtnLoading(true)
        let res;
        if (endsWithTH(redeemCode)) {
            // Call another endpoint if redeemCode ends with "TH"
            res = await onRedeemCodeWithTH(redeemCode, globalToken);
            if (res.success) {
                setRedeemedCredits(res?.data?.credits);
                toast.success(res?.data?.message);
                setBtnLoading(false);
            } else {
                setRedeemedCredits(0);
                if(res?.status==5){
                    toast.error(res?.errors);
                }else{
                    toast.error(res?.errors?.code[0] || res?.message);
                }
                
                setBtnLoading(false);
            }
        } else {
            // Call the original endpoint
            res = await onRedeemQuizCredits(user?.tuteehub_id, redeemCode, globalToken);
            if (res.success) {
                setRedeemedCredits(res?.redeemed_credits);
                setBtnLoading(false);
            } else {
                setRedeemedCredits(0);
                if(res?.status==5){
                    toast.error(res?.errors);
                }else{
                    toast.error(res?.errors?.code[0] || res?.message);
                }
               
                setBtnLoading(false);
            }
        }

       
        // const res = await onRedeemQuizCredits(user?.tuteehub_id, redeemCode, qToken);
        
        // if(res.success){
        //     setRedeemedCredits(res?.redeemed_credits);
            
        // }else{
        //     setRedeemedCredits(0);
        //     toast.error(res?.errors?.code[0] || res?.message);
        //     setBtnLoading(false)

        // }
    };

    useEffect(()=>{
        
        getUserInfo(user?.tuteehub_id)  
        if(redeemedCredits>0){
        setShowAnimatedCareditMessage(true);          
        getUserInfo(user?.tuteehub_id)          
            setTimeout(() => {
            setShowAnimatedCareditMessage(false);            
        setRedeemedCredits(0)
            }, 600); 
            
        setBtnLoading(false)
        fetchCreditHistory(choosenModule);
        }else{
            setBtnLoading(false)
        }
        
    },[redeemedCredits])




    const fetchBankDetails = async () => {
        try {
          const res = await getAccountDetail(user?.tuteehub_id);
          console.log(res)
          if (res?.status == 1){
            setBankDetails(res?.data);
            
          }
        } catch (error) {
        }
      };


    // withdrawal request 
    const handWithdrawalRequest = async() =>{        
       if(!bankDetails?.id){
        toast.error('Banking detail not available');
        return;
       }
        setBtnLoading(true)
        // if(totalCredits<minWithdrawalLimit){
        // toast.error("Insufficient credit balance for this request.");
        // return
        // }

        const withdrawalParams = {
            tuteehub_id:user?.tuteehub_id,
            redeemed_coins: creditToWithdrawal,
            amount: Number(creditToWithdrawal/amountByCredit),
            module:choosenModule
        }
     
        const res = await sendWithdrawalRequest(withdrawalParams);
     
        if (res.success) {
                toast.success('Request submitted');
                getUserInfo(user?.tuteehub_id);
                // console.log(user);
                fetchWithdrawalRequestList();
                const cf1 = document.getElementById('clear_f_1');
                cf1.value = null;
                setCreditToWithdrawal(0)
        }else{
           
            if (res?.errors && typeof res.errors === 'object') {
            Object.keys(res?.errors).forEach(field => {
                (res?.errors)[field].forEach(errorMessage => {
                    toast.error(`${field.charAt(0).toUpperCase() + field.slice(1)}: ${errorMessage}`);
                });
            });
        }else{
            toast.error(res?.errors || res?.message);
        }
        }
        setBtnLoading(false)

    }

    const goToDetailTask = (url, usrTsk, taskDetailType) => {
        // localStorage.setItem('usrTsk', JSON.stringify(usrTsk || []));
        localStorage.setItem('taskDetailType', taskDetailType);
        window.location.href = url;
    }
    return (
        <>
            <BreadCrumbs />
            {/* <div className="grid grid-cols-12 graph-card-box text-center py-3 px-0">
                <div className="col-span-5 text-card flex items-center">
                    <h2
                        className="heading-star px-0 flex items-center text-left cursor-pointer"
                        style={{ color: "#021442" }}
                    // onClick={() => navigate(-1)}
                    >
                        Finance
                    </h2>
                </div>
            </div> */}
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4 finance">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <Tabs defaultActiveKey={currentRoute=='/weekly-leaderboard'?1:currentRoute=='/credit-history'?2:currentRoute=='/transaction-history'?3:currentRoute=='/withdrawal'?4:currentRoute=='/credit-purchase-history'?5:6} id="uncontrolled-tab-example" className="mx-3">
                        <Tab eventKey={1} title="Leaderboard" className="p-3">
                                        {leaderBoardList?.length ? (
                                            <div className="row">
                                                <div className="col-md-5">
                                                <div className="card mb-3  pt-3 border-0 shadow top-leader-board-container" style={{borderColor:'#f0f0f0'}}>
                                                         <div className="leader-board-1 mb-4 leaderboard text-center position-relative">
                                                                
                                                                <div className="circle-leader-board-1 position-relative">
                                                                    <img src={crown} className="crown"/>
                                                                    <div className="wing-left float-left rtl text-right">
                                                                        <div style={{width:25,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                        <div style={{width:20,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                        <div style={{width:15,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                    </div>
                                                                    <div className="wing-right float-right text-right">
                                                                        <div style={{width:25,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                        <div style={{width:20,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                        <div style={{width:15,height:4,backgroundColor:'#ffc329',borderRadius:10,marginBottom:4}}></div>
                                                                    </div>
                                                                    <div className="scoretab score-first m-auto">
                                                                    {nFormatter(leaderBoardList[0].user_credit)}
                                                                    </div>
                                                                    <div className="leaderboard-img-container d-flex align-items-center leaderboard-1">
                                                                    <div
                                                                                    className="rounded-circle text-white d-flex align-items-center justify-content-center"
                                                                                    style={{ width: '60px', height: '60px', fontSize: '1.5rem', margin: '0 auto',backgroundColor:generateRandomColor(),transition: 'background-color 0.5s ease',opacity:0.6 }}
                                                                                >{leaderBoardList[0]?.user_name ? leaderBoardList[0]?.user_name.slice(0, 2).toUpperCase() : 'NA'}</div>
                                                                        {/* <img src={leaderPic} className="leaderboardUsr leader-1 usrOne"/> */}
                                                                    </div>
                                                                    
                                                                </div>
                                                                <h5 className="text-center mb-0 text-capitalize"><a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${leaderBoardList[0]?.username}`} target='_blank' className="theme-hover-color">{leaderBoardList[0].user_name}</a></h5>
                                                                <p className="text-center mb-0 text-black-50"><small>{leaderBoardList[0].user_email}</small></p>
                                                         </div>
                                                         <div className="row mt-0">
                                                            <div className="col-md-6 text-center">
                                                                   
                                                                    <div className="circle-leader-board-2 position-relative">
                                                                    <div className="second-txt">2</div>
                                                                        <div className="scoretab score-second m-auto">
                                                                        {nFormatter(leaderBoardList[1].user_credit)}
                                                                        </div>
                                                                        <div className="leaderboard-img-container d-flex align-items-center  leaderboard-2">
                                                                      
                                                                    <div
                                                                                    className="rounded-circle text-white d-flex align-items-center justify-content-center"
                                                                                    style={{ width: '60px', height: '60px', fontSize: '1.5rem', margin: '0 auto',backgroundColor:generateRandomColor(),transition: 'background-color 0.5s ease',opacity:0.6 }}
                                                                                >{leaderBoardList[1]?.user_name ? leaderBoardList[1]?.user_name.slice(0, 2).toUpperCase() : 'NA'}</div>
                                                                        
                                                                            {/* <img src={leaderPic} className="leaderboardUsr leader-2 usrOne"/> */}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <h5 className="text-center mb-0 text-capitalize"><a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${leaderBoardList[1]?.username}`} target='_blank' className="theme-hover-color">{leaderBoardList[1].user_name}</a></h5>
                                                                <p className="text-center mb-0 text-black-50"><small>{leaderBoardList[1].user_email}</small></p>
                                                            </div>
                                                            <div className="col-md-6 text-center"> 
                                                                   
                                                                    <div className="circle-leader-board-3 position-relative">
                                                                    <div className="third-txt">3</div>
                                                                        <div className="scoretab score-third m-auto">
                                                                        {nFormatter(leaderBoardList[2].user_credit)}
                                                                        </div>
                                                                        <div className="leaderboard-img-container leaderboard-2 d-flex align-items-center">
                                                                        <div
                                                                                    className="rounded-circle text-white d-flex align-items-center justify-content-center"
                                                                                    style={{ width: '60px', height: '60px', fontSize: '1.5rem', margin: '0 auto',backgroundColor:generateRandomColor(),transition: 'background-color 0.5s ease',opacity:0.6 }}
                                                                                >{leaderBoardList[2]?.user_name ? leaderBoardList[2]?.user_name.slice(0, 2).toUpperCase() : 'NA'}</div>
                                                                        
                                                                            {/* <img src={leaderPic} className="leaderboardUsr leader-3 usrOne"/> */}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <h5 className="text-center mb-0 text-capitalize"><a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${leaderBoardList[2]?.username}`} target='_blank' className="theme-hover-color">{leaderBoardList[2].user_name}</a></h5>
                                                                <p className="text-center mb-0 text-black-50"><small>{leaderBoardList[2].user_email}</small></p>
                                                            </div>
                                                         </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                        <div className="row">
                                                             {
                                                                leaderBoardList?.map((leaderBoard, index)=>(
                                                                    <>
                                                                        {
                                                                            index>2? <div className="col-md-12">
                                                                            <div className="card mb-3 border-0 shadow" style={{borderColor:'#f0f0f0'}}>
                                                                            <div className="card-body d-flex justify-content-between align-items-center">
                                                                                <div className="d-flex">
                                                                                <div
                                                                                    className="me-3 rounded-circle text-white d-flex align-items-center justify-content-center"
                                                                                    style={{ width: '60px', height: '60px', fontSize: '1.5rem', margin: '0 auto',backgroundColor:generateRandomColor(),transition: 'background-color 0.5s ease',opacity:0.6 }}
                                                                                >
                                                                                    {leaderBoard?.user_name ? leaderBoard?.user_name.slice(0, 2).toUpperCase() : 'NA'}
                                                                                </div>
                                                                                <div>
                                                                                    <h6 className="card-title mt-0 text-capitalize text-"><a href={`${SHOWTALENT_USER_DETAIL_BASE_LINK}${leaderBoard?.username}`} target='_blank' className="theme-hover-color">{leaderBoard?.user_name}</a></h6>
                                                                                    <small style={{    color: "rgba(0,0,0,0.3)"}}>Rank #{index+1} .</small> <small style={{    color: "rgba(0,0,0,0.3)"}}>{leaderBoard?.user_email}</small>
                                                                                    
                                                                                </div>
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                                                <h6 className="mb-0">{nFormatter(leaderBoard?.user_credit)}</h6> 
                                                                                    <small style={{    color: "rgba(0,0,0,0.3)"}}>Credits</small>
                                                                                </div>
                                                                            </div>
                                                                        </div></div>:null
                                                                        }
                                                                    </>
                                                                   
                                                                ))
                                                            }
                                                        </div>
                                                </div>
                                            
                                                
                                            </div>
                                        ) : (
                                            <div className="flex-column not-found d-flex text-center">
                                                <img src={notFound} className="notFoundImg" />
                                                <span style={{ opacity: 0.7 }}>No any leaderboard</span>
                                            </div>
                                        )}
                                    </Tab>

                            <Tab eventKey={2} title="Credit History" className="p-3">

                                <div>
                                    <h5><strong>Credits: <span style={{ color: '#ff7200' }}>{totalCredits}</span></strong></h5>
                                </div>
                               
                                <div className="card mb-3 border-0 shadow">
                                    <div className="card-body">
                                        <div className="d-flex flex-md-row flex-column">
                                            <div className="flex-1">
                                                <label className="mb-2">Filter By Module</label>
                                                <select value={choosenModule} onChange={handleModuleChange} className="form-control">
                                                    <option disabled>Filter By Module</option>
                                                    {
                                                        modules?.map(module => (<option value={module?.value}>{module?.name}</option>))
                                                    }
                                                </select>

                                            </div>
                                            <div className="flex-1 d-flex justify-content-end flex-md-row flex-column">
                                                <div className="mt-4 mt-md-0">
                                                    <label className="mb-2">From Date</label>
                                                    <div className="dob-datepicker-container position-relative w-100 mb-3">
                                                    <DatePicker
                                                            ref={fromDatepickerRef}
                                                            selected={fromDate}
                                                            onChange={(date) => {setFromDate(date)}}
                                                            maxDate={new Date()}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            dropdownMode="select"
                                                            placeholderText="From Date"
                                                            className="form-control"
                                                            onKeyDown={(e) => e.preventDefault()} 
                                                            onFocus={(e) => e.target.blur()} 
                                                            style={{border:'none'}}
                                                        />
                                                        <FaCalendarAlt
                                                            style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "45%",
                                                            transform: "translateY(-50%)",
                                                            cursor: "pointer",
                                                            color: "#888",
                                                            }}
                                                            onClick={() => fromDatepickerRef.current.setFocus()}
                                                        />
                                                        </div>
                                                    {/* <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="From Date"
                                                        value={fromDate}
                                                        onChange={(e) => setFromDate(e.target.value)}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    /> */}
                                                    {/* <small className="text-black-50" style={{fontSize:'0.6rem'}}>Date Format: mm-dd-yyyy</small> */}
                                                </div>
                                                <div className="ms-md-2 mt-md-0 mt-4">
                                                    <label className="mb-2">To Date</label>
                                                    <div className="dob-datepicker-container position-relative w-100 mb-3">
                                                        <DatePicker
                                                            ref={toDatepickerRef}
                                                            selected={toDate}
                                                            onChange={(date) => {setToDate(date)}}
                                                            maxDate={new Date()}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            dropdownMode="select"
                                                            placeholderText="To Date"
                                                            className="form-control"
                                                            onKeyDown={(e) => e.preventDefault()} 
                                                            onFocus={(e) => e.target.blur()} 
                                                            style={{border:'none'}}
                                                        />
                                                        <FaCalendarAlt
                                                            style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "45%",
                                                            transform: "translateY(-50%)",
                                                            cursor: "pointer",
                                                            color: "#888",
                                                            }}
                                                            onClick={() => toDatepickerRef.current.setFocus()}
                                                        />
                                                        </div>
                                                    {/* <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="To Date"
                                                        value={toDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        min={fromDate}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    /> */}
                                                    {/* <small className="text-black-50" style={{fontSize:'0.6rem'}}>Date Format: mm-dd-yyyy</small> */}

                                                </div>
                                                {/* <div className="ms-2 flex-column d-flex">
                                                    <label className="mb-2 opacity-0">d</label>
                                                    <button type="button" onClick={()=>filterByDate()} className="btn bg-theme"> <i className="bx bx-filter"></i></button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                {
                                    choosenModule=='gigs'? 
                                        <div className="card mb-3 border-0 shadow">
                                            <div className="card-body table-responsive">
                                                <div className="d-flex justify-content-between align-items-center">
                                                     <div>
                                                        <h6 className="mb-0">Earned Credits: <span className="text-success">{gigsCreditsStats?.approve_credits || 0}</span></h6>
                                                     </div>
                                                     <div>
                                                     <h6 className="mb-0">Pending Credits: <span className="text-warning">{gigsCreditsStats?.pending_credits || 0}</span></h6>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>:null
                                }
                                {
                                    choosenModule!=='gigs' && filteredCreditHistory?.length ? <>
                                        <div className="card mb-3 border-0 shadow">
                                            <div className="card-body table-responsive">
                                        <table className="table table-bordered mt-2">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Module</th>
                                                    <th>Action Type</th>
                                                    <th>Credit</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredCreditHistory?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(creditHistory => (
                                                        <>
                                                            <tr>
                                                                <td>{creditHistory?.id}</td>
                                                                <td>{creditHistory?.module}</td>
                                                                <td>{creditHistory?.action}</td>
                                                                <td>{creditHistory?.credits > 0 ? (
                                                                    <span className="label-success">{creditHistory?.credits}</span>
                                                                ) : (
                                                                    <span className="label-danger">{creditHistory?.credits}</span>
                                                                )}</td>
                                                                <td>{creditHistory?.description || 'NA'}</td>
                                                                <td>{formatDate(creditHistory?.created_at)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table></div></div>
                                    </> :choosenModule=='gigs' && filteredGigsCreditHistory?.length ? <>
                                    {/* {
                                        console.log(filteredGigsCreditHistory)
                                    } */}
                                        <div className="card mb-3 border-0 shadow">
                                           
                                            <div className="card-body table-responsive">
                                        <table className="table table-bordered mt-2">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Module</th>
                                                    <th>Task</th>
                                                    <th>Credit</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredGigsCreditHistory?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map(gigHistory => (
                                                        <>
                                                            <tr>
                                                                <td>{gigHistory?.id}</td>
                                                                <td>Gigs</td>
                                                                <td>
                                                                    {
                                                                        gigHistory?.campaign?<Link to={`/gigs/tasks/${slugify(gigHistory?.campaign?.title)}-${gigHistory?.campaign?.id}`}>{gigHistory?.campaign?.title}</Link>:'NA'
                                                                    }
                                                                    </td>
                                                                <td>{gigHistory?.action == 'credit' ? (
                                                                    <span className="label-success">{gigHistory?.credits}</span>
                                                                ) : (
                                                                    <span className="label-danger">{- gigHistory?.credits}</span>
                                                                )}</td>
                                                                <td className="text-capitalize capitalize">{gigHistory?.description || 'NA'}</td>
                                                                <td>{formatDate(gigHistory?.created_at)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table></div></div>
                                    </>: <div className="flex-column not-found d-flex text-center" >
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>No credit history</span>
                                    </div>
                                }

                            </Tab>
                            <Tab eventKey={3} title="Transactions" className="p-3">
                                {
                                    transactionHistoryList?.length ? <>
                                        <h5 style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {/* <strong>Total Amount : <span style={{ color: '#ff7200' }}>INR {totalTransactions}</span></strong> */}
                                            <div>
                                            <span className="label failed-label label-danger"> Fail: {failedTransactions}</span>
                                            <span className="label success-label ms-1 label-success"> Success: {successTransactios}</span>
                                        </div></h5>
                                        <hr style={{ borderColor: '#ccc' }} />
                                        <div className="card mb-3 border-0 shadow">
                                            <div className="card-body table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Transaction ID</th>
                                                    <th>Credits</th>
                                                    <th>Amount(INR)</th>
                                                    <th>Status</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    transactionHistoryList?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map(transactionHistory => (
                                                        <>
                                                            <tr>
                                                                <td>{transactionHistory?.transaction_id}</td>
                                                                <td>{transactionHistory?.credits}</td>
                                                                <td>{transactionHistory?.amount}</td>
                                                                <td>{transactionHistory?.status == "success" ? (
                                                                    <span className="text-success text-capitalize text-left">{transactionHistory?.status}</span>
                                                                ) : (
                                                                    <span className="text-danger text-capitalize text-left">{transactionHistory?.status}</span>
                                                                )}</td>
                                                                <td>{transactionHistory?.description || 'NA'}</td>
                                                                <td>{formatDate(transactionHistory?.created_at)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table></div></div>
                                    </> : <div className="flex-column not-found d-flex text-center" >
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>No transaction history</span>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey={4} title="Withdrawal Requests" className="p-3">
                            {
                                    !bankDetails?.id && showHideWithdrawal?<div className="alert alert-warning w-100 mb-3">Attention: Ensure a seamless withdrawal process by securely linking your banking details. <button onClick={()=>navigation('/payment-details')} style={{color:'blue'}}>Click here</button> to complete the banking details setup now.</div>:null
                                }
                            {
                                            showHideWithdrawal?<div>
                                            <h5 className="d-flex align-items-center justify-content-between"><strong>Credits: <span style={{ color: '#ff7200' }}>{totalCredits}</span></strong><button className="btn btn-secondary" onClick={()=>setShowHideWithdrawal(!showHideWithdrawal)} style={{opacity:0.3}}>X</button></h5>
                                
                                            <div className="card mb-3 border-0 shadow">
                                    <div className="card-body">
                                        <div className="d-flex mb-2 flex-md-row flex-column">
                                            <div className="flex-2  mb-md-0 mb-3">
                                                <label className="mb-2">Credits</label>
                                                <input type="number" id="clear_f_1" 
                                                // disabled={(totalCredits>minWithdrawalLimit)?false:true} 
                                                 onChange={(e)=>setCreditToWithdrawal(e.target.value)} placeholder="Enter credits to withdraw" max={totalCredits} className="form-control"/>

                                            </div>
                                            <div className="flex-1 ms-2 mb-md-0 mb-3">
                                                <label className="mb-2">Withdrawal Amount</label>
                                                <div className="input-group">
                                                <span class="input-group-text" id="basic-addon1">{currencyType}</span>
                                                <input type="number" id="clear_f_2" value={(creditToWithdrawal/amountByCredit)?.toFixed(2)}  max={totalCredits} className="form-control" disabled/>
                                                
                                                </div>

                                            </div>
                                            <div className="flex-1 ms-2">
                                                <label className="mb-2">System Commission</label>
                                                <div className="input-group">
                                                <span class="input-group-text" id="basic-addon1">{currencyType}</span>
                                                <input type="number" value={((creditToWithdrawal/amountByCredit)*(withdrawalConfiguration?.withdrawal_commission_percentage/100))?.toFixed(2)}  max={totalCredits} className="form-control" disabled/>
                                                
                                                </div>

                                            </div>
                                            {/* <div className="flex-1 d-flex justify-content-end"> */}
                                               
                                                <div className="ms-2 flex-column d-flex">
                                                    <label className="mb-2 opacity-0">d</label>
                                                    {/* creditToWithdrawal>=minWithdrawalLimit &&  totalCredits>minWithdrawalLimit*/}
                                                    <button  type="button"
                                                     disabled={btnLoading || (creditToWithdrawal > 0)?false:true

                                                     } 
                                                     onClick={()=>handWithdrawalRequest()}  className="btn bg-theme text-white" style={{backgroundColor:(creditToWithdrawal > 0)?'#ff7200':'#ccc'}}>  {btnLoading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Send Withdrawal Request"
                    )}</button>
                                                </div>
                                            {/* </div> */}
                                        </div>
                                        <small className="mt-3 text-black-50">Note: Minimum withdrawal credit should be <strong>{minWithdrawalLimit}</strong></small>
                                    </div>
                                </div>
                                        </div>: <div className="d-flex mb-3 justify-content-between align-items-center">
                                                <div>
                                                <h4 className="mb-2 text-secondary">{withdrawalRequestList[0]?.user_name}</h4>
                                                <h6>Remaining Credits: <strong style={{color:'#ff7200'}}>{totalCredits}</strong></h6>
                                                </div>
                                                <div>
                                                <button className="text-white px-3 py-2 rounded bg-theme" onClick={()=>{ setShowHideWithdrawal(!showHideWithdrawal)}}>Withdrawal</button>
                                                </div>
                                        </div>
                                            
                                        }
                            {
                                    withdrawalRequestList?.length ? <>
                                        {/* <h5 style={{ display: 'flex', justifyContent: 'space-between' }}><strong>Total Amount : <span style={{ color: '#ff7200' }}>INR {totalTransactions}</span></strong><div>
                                            <span className="label failed-label label-danger"> Fail: INR {failedTransactions}</span>
                                            <span className="label success-label ms-1 label-success"> Success: INR {successTransactios}</span>
                                        </div></h5> */}
                                        {/* <hr style={{ borderColor: '#ccc' }} /> */}
                                       
                                       
                                       
                                        <div className="card mb-3 border-0 shadow">
                                            <div className="card-body table-responsive">

                                                    <table className="table table-bordered data-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Transaction ID</th>
                                                                <th>Redeemed Credits</th>
                                                                <th>Amount(INR)</th>
                                                                <th>Commission(INR)</th>
                                                                <th>Status</th>
                                                                <th>Remark</th>
                                                                <th>Request Date</th>
                                                                <th>Processing Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {console.log(withdrawalRequestList)}
                                                            {
                                                                withdrawalRequestList?.map(withdrawalRequest => (
                                                                    <>
                                                                        <tr className={`${withdrawalRequest?.status == 4 || withdrawalRequest?.status == 5 ? 'table-danger' :withdrawalRequest?.status == 1 || withdrawalRequest?.status == 2 ? 'table-warning': withdrawalRequest?.status == 3 ?'table-success':''}`}>
                                                                            <td>{(withdrawalRequest?.transaction_id)?withdrawalRequest?.transaction_id:`--`}</td>
                                                                            <td>{withdrawalRequest?.redeemed_coins}</td>
                                                                            <td>{withdrawalRequest?.amount}</td>
                                                                            <td>{withdrawalRequest?.commission || 0.00}</td>
                                                                            <td>{withdrawalRequest?.status == 3 ? (
                                                                                <span className="text-success text-left text-capitalize">{withdrawalStatus[withdrawalRequest?.status - 1]?.label}</span>
                                                                            ) :withdrawalRequest?.status == 1 || withdrawalRequest?.status == 2? (
                                                                                <span className="text-warning text-left text-capitalize">{withdrawalStatus[withdrawalRequest?.status - 1]?.label}</span>
                                                                            ) : (
                                                                                <span className="text-danger text-left text-capitalize">{withdrawalStatus[withdrawalRequest?.status - 1]?.label}</span>
                                                                            )}</td>
                                                                            <td>{withdrawalRequest?.remarks || 'NA'}</td>
                                                                            <td>{formatDate(withdrawalRequest?.request_date)}</td>
                                                                            <td>{(withdrawalRequest?.processing_date)?formatDate(withdrawalRequest?.processing_date):`--`}</td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table></div></div>
                                    </> : <div className="flex-column not-found d-flex text-center" >
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>No withdrawal history</span>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey={5} title="Credits Purchase History" className="p-3">
                                <div class="card mb-3 border-0 shadow mb-3">
                                    <div className="card-body">
                            <div className="d-flex flex-md-row flex-column">
                                            <div className="flex-1">
                                               {/* <h5><strong>Total Amount : <span style={{ color: '#ff7200' }}>INR {totalTransactions}</span></strong></h5> */}

                                            </div>
                                            <div className="flex-1 d-flex justify-content-end flex-md-row flex-column">
                                                <div className="mt-4 mt-md-0">
                                                    <label className="mb-2">From Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="From Date"
                                                        value={fromDate}
                                                        onChange={(e) => setFromDate(e.target.value)}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    />
                                                    {/* <small className="text-black-50" style={{fontSize:'0.6rem'}}>Date Format: mm-dd-yyyy</small> */}
                                                </div>
                                                <div className="ms-md-2 mt-md-0 mt-4">
                                                    <label className="mb-2">To Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="To Date"
                                                        value={toDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        min={fromDate}
                                                        max={moment().format('YYYY-MM-DD')}
                                                    />
                                                    {/* <small className="text-black-50" style={{fontSize:'0.6rem'}}>Date Format: mm-dd-yyyy</small> */}

                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                            {
                                    
                                    filteredCoinPurchaseHistoryList?.length ? <>
                                            <div className="card mb-3 border-0 shadow">
                                                <div className="card-body table-responsive">

                                            
                                            <table className="table table-bordered mt-2">
                                                <thead>
                                                    <tr>
                                                        <th>Transaction ID</th>
                                                        <th>Amount</th>
                                                        <th>Credits</th>
                                                        <th>Description</th>
                                                        <th>Status</th>
                                                        <th>Date</th>
                                                        <th>Invoice</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredCoinPurchaseHistoryList?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(creditHistory => (
                                                            <>
                                                                <tr>
                                                                    <td>{creditHistory?.transaction_id}</td>
                                                                    <td>{creditHistory?.currency} {creditHistory?.final_amount}</td>
                                                                    <td>{creditHistory?.credits > 0 ? (
                                                                        <span className="label-success">{creditHistory?.credits}</span>
                                                                    ) : (
                                                                        <span className="label-danger">{creditHistory?.credits}</span>
                                                                    )}</td>
                                                                    <td>{'Payment successfully proceeded' || creditHistory?.description}</td>
                                                                    <td><span className="label-success">{creditHistory?.status}</span></td>
                                                                    <td>{formatDate(creditHistory?.created_at)}</td>
                                                                    <td><a target="_blank" href={INVOICE_BASE_URL+encryptData((creditHistory?.id).toString())} title="Invoice" className="btn edit-btn"><ReceiptIcon/></a></td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
    
                                                </tbody>
                                            </table></div></div>
                                    </> : <div className="flex-column not-found d-flex text-center" >
                                        <img src={notFound} className="notFoundImg" />
                                        <span style={{ opacity: 0.7 }}>No credits purchase history</span>
                                    </div>
                                }
                            </Tab>
                            <Tab eventKey={6} title="Claim Credits" className="p-3">
                            
                            <div className="card mb-3 py-md-5 py-3 mh-100 border-0 shadow">
                                    <div className="card-body">
                                    <div className="mb-4">
                                            <h5><strong>Credits: <span style={{ color: '#ff7200' }}>{totalCredits}</span></strong>
                                            {
                                            showAnimatedCareditMessage ?<div className="ms-2 fade-in-message" id="animated-added-credit">+{redeemedCredits} Credit Added!</div>:null
                                        }
                                            </h5>
                                        </div>
                                       
                                    
                                        <div className="d-md-flex">
                                            <div className="flex-1">
                                            {/* at the time of quiz completion */}
                                                <label className="mb-2">Enter the token that you have received</label>
                                                <input type="text" onChange={(e)=>setRedeemCode(e.target.value)}  placeholder="e.g 82779992X06138313D" max={totalCredits} className="form-control"/>

                                            </div>
                                           
                                            {/* <div className="flex-1 d-flex justify-content-end"> */}
                                               
                                                <div className="ms-2 flex-column d-flex">
                                                    <label className="mb-2 opacity-0">d</label>
                                                    <button disabled={btnLoading || !redeemCode?.length}  type="button" onClick={()=>handleClaimCredits()} className="btn bg-theme text-white" style={{backgroundColor:(btnLoading || !redeemCode?.length)?'#ccc':'#ff7200'}}>  {btnLoading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Claim Credits"
                    )}</button>
                                                </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                        </Tabs>
                    </>
                )
                }
            </div>
        </>
    );
}
export default Finance;
