import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { getGigsConfigurations, getPlansList, getUserInfo } from "../../../api/OurService";
import { Loadingicon } from "../../../AppIcons";
import { useAuth } from "../../../Context/AuthContext";
import BreadCrumbs from "../../common/BreadCrumbs";
import { Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { CDN_BASE_URL, fetchUserInfo, formatDate, generateRandomColor, viewRecentVisits } from "../../../utils/utils";
import coin from '../../../assets/images/coin.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from "moment";
import RazorPay from "../../common/razorpay";
import RazorPayPayment from "../../common/razorpay";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../common/customselect";
import indianStates from '../../../../src/utils/indiaStates.json';
import { plansWithTax, userAddressUpdate, userProfileUpdate } from "../../../api/AuthService";
function Plans() {
    const [loading, setLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [taxCalcLoader, setTaxCalcLoader] = useState(true);
    const [isIndia, setIsIndia] = useState(false);
    const [taxationsDetail, setTaxationsDetail] = useState(null);
    const [choosenPlan, setChoosenPlan] = useState({});
    const [coinPlansList, setCoinPlansList] = useState([]);
    const [isAddressModal, setIsAddressModal] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [userLocation, setUserLocation] = useState(false);

    const [isCountryLoading, setIsCountryLoading] = useState(true);
    const [countryFormatted, setCountryFormatted] = useState();
    const [defaultCountry, setDefaultCountry] = useState(null);
    const [preSelectedCountry, setPreSelectedCountry] = useState(null);

    const navigate = useNavigate();

    const DisplayingErrorMessagesSchemaIndia = Yup.object().shape({
        state: Yup.string().trim().required("State is required"),
        country: Yup.string().trim().required("Country is required")
    });
    const DisplayingErrorMessagesSchema = Yup.object().shape({
        country: Yup.string().trim().required("Country is required")
    });

    const { user, globalToken, setUser, setFinance } = useAuth();
    const background = [{
        gradient: 'linear-gradient(45deg, #2a46ff, #c8c8c8)',
        color: '#2a46ff'
    }, {
        gradient: 'linear-gradient(45deg, #e35cff, #ff6868)',
        color: 'rgb(255 104 106)'
    }, {
        gradient: 'linear-gradient(45deg, #488542, #58ff80)',
        color: '#488542'
    }, {
        gradient: 'linear-gradient(45deg, #657006, #e0f55a)',
        color: '#657006'
    }, {
        gradient: 'linear-gradient(45deg, #00f3dd, #21a58f)',
        color: 'rgb(32 166 144)'
    }, {
        gradient: 'linear-gradient(45deg, #ff2525, #ffa2a2)',
        color: '#ff2525'
    }
    ]




    const fetchPlans = async () => {
        try {
            const res = await getPlansList();
            setCoinPlansList(res?.response?.result);
            setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
            console.error("Error fetching plans:", error);
            setLoading(false); // Set loading to false in case of an error
        }
    };



    const openHideAddressModal = () => {
        if(isAddressModal){
            setIsIndia(false);
        }
        setIsAddressModal(!isAddressModal)
    }


    useEffect(() => {
        // alert(choosenPlan?.amount)
        if (isFirstStep == false && choosenPlan?.amount) {
            handlePlanMoreInfoWithTax()
        }
    }, [choosenPlan, isFirstStep])



    const handlePlanMoreInfoWithTax = async () => {
        setTaxCalcLoader(true)
        const planWithTaxRes = await plansWithTax({ token: globalToken, amount_in_rupees: choosenPlan?.amount });
        if (planWithTaxRes?.success) {
            setTaxationsDetail(planWithTaxRes?.data)

        } else {
            toast.error(planWithTaxRes?.message)
        }
        setTaxCalcLoader(false)
    }

    const handleConfig = async () => {
        try {
            const gigsConfRes = await getGigsConfigurations({ token: globalToken, conf: 'country' });
            if (gigsConfRes?.success) {
                const countryData = gigsConfRes.data?.country?.map(option => ({
                    value: option.countryname,
                    label: option.countryname,
                    flag: CDN_BASE_URL + option?.image
                })) || [];
                setCountryFormatted(countryData);
                setIsCountryLoading(false);
            }
        } catch (error) {
            console.error('Error fetching gigs configurations:', error);
        }
    }

    useEffect(() => {
        handleConfig();
        viewRecentVisits(globalToken);
        // user?.address && 
        if (user?.country) {
            setIsFirstStep(false);
            if ((user?.country)?.toLowerCase() == 'india') {
                setIsIndia(true)
            }
            // handlePlanMoreInfoWithTax();
        }
        fetchPlans();
    }, [])



    const formData = [{
        id: 'country',
        title: 'Country',
        type: 'text',
        name: 'country',
        placeholder: 'Pick one from the list',
        required: true,
        editable: true,
        isCountry: true
    }, {
        id: 'state',
        title: 'State',
        type: 'text',
        name: 'state',
        placeholder: '',
        required: true,
        editable: true,
        isState: true
    }

    ]



    const handleAddressSave = async (values) => {
        setBtnLoader(true)
        if (!isIndia) {
            delete values?.state;
        }
        values = { ...values, token: globalToken, type:'location' };
        
        try {

            const res = await userProfileUpdate(values);
            if (res?.success) {
                fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user)
                setUserLocation(true);
                setIsFirstStep(false);
            } else {
                toast.error(res?.message)
            }
        } catch (error) { setBtnLoader(false)}
        setBtnLoader(false)

    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {
        if (type == 'country') {
            if (value?.toLowerCase() == 'india') {
                setIsIndia(true);
            } else {
                setIsIndia(false);
                setFieldValue('state', null)
            }
            setFieldValue('country', value);
        }
    }
    return (
        <>
            <BreadCrumbs />
            {/* <div className="grid grid-cols-12 graph-card-box text-center py-3 px-0">
                <div className="col-span-5 text-card flex items-center">
                    <h2
                        className="heading-star px-0 flex items-center text-left cursor-pointer"
                        style={{ color: "#021442" }}
                    // onClick={() => navigate(-1)}
                    >
                        Plans
                    </h2>
                </div>
            </div> */}
            <div className=" py-4 px-0 grid grid-cols-1 group-card card-body gap-4">
                {loading ? (
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="p-5">
                            <div className="row">
                                {

                                    coinPlansList?.length ? coinPlansList?.sort((a, b) => a.amount - b.amount).map((coinPlan, index) => (
                                        <div className="col-md-2 position-relative" style={{ cursor: 'pointer' }} onClick={() => setChoosenPlan(coinPlan)}>

                                            {/* {
                                      
                                        coinPlan?.id == choosenPlan?.id?<div className="choosen-plan-check">
                                            <CheckCircleIcon style={{ color: "#ff7200",fontSize:40 }} />
                                        </div>:null
                                    } */}
                                            {/* <div className="card mb-3 border-0 shadow" style={{borderColor:'#f0f0f0'}}>
                                    <div className="card-body d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div>
                                            <img src={coin}/>
                                            </div>
                                            <div className="ms-3">
                                            <h5 className="m-0">{coinPlan?.plan_name}</h5>
                                            <p className="m-0 text-black-50"><small>{coinPlan?.coins_number} coins</small></p>
                                            </div>
                                        </div>
                                        <div>

                                            <h5 className="text-secondary">INR {coinPlan?.amount}</h5>
                                        
                                        </div>
                                    </div>
                                </div> */}
                                            <div className="card mb-3 border-0 shadow" style={{ borderColor: '#f0f0f0' }}>
                                                <div className="card-body d-flex flex-column justify-content-between align-items-center" style={{ background: background[index].gradient, position: 'relative', overflow: 'hidden' }}>
                                                    {
                                                        index == 4 ? <span className="recommended-tag">Recommended</span> : null
                                                    }
                                                    {
                                                        index == 3 ? <span className="popular-tag">Popular</span> : null
                                                    }

                                                    <div className="d-flex flex-column align-items-center">
                                                        <div>
                                                            <img src={coin} />
                                                        </div>
                                                        <div className="my-4">
                                                            {/* <h5 className="m-0">{coinPlan?.plan_name}</h5> */}
                                                            <p className="m-0 text-white"><small>{coinPlan?.coins_number} Credits</small></p>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <h5 className="text-white text-center mt-4">INR {coinPlan?.amount}</h5>
                                                        <div>
                                                            <button onClick={() => { openHideAddressModal() }} className="btn bg-theme px-3 mt-4 float-right" style={{ background: 'white', color: background[index].color }}>
                                                                {`Buy Now`}
                                                            </button>
                                                            {/* <RazorPayPayment colors={background[index].color} setChoosenPlan={setChoosenPlan} params={{
                                                tuteehub_id:user.tuteehub_id,
                                                credits:coinPlan?.coins_number,
                                                amount:Number(coinPlan?.amount),
                                                platform_name:'Razorpay',
                                                ip:"",
                                                user_agent:'Desktop'
                                            }}/> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : null
                                }


                            </div>
                            {/* {
                        Object.keys(choosenPlan).length?<RazorPayPayment setChoosenPlan={setChoosenPlan} params={{
                            tuteehub_id:user.tuteehub_id,
                            credits:choosenPlan.coins_number,
                            amount:Number(choosenPlan?.amount),
                            platform_name:'Razorpay',
                            ip:"",
                            user_agent:'Desktop'
                        }}/>:null
                       } */}
                        </div>
                    </>
                )
                }
            </div>

            <Modal
                show={isAddressModal}
                onHide={openHideAddressModal}
                size="md"
                centered

            >
                <Modal.Body>
                    {
                        !user?.can_user_purchase && !userLocation ? <div className="address-step">
                            <h5><strong>Location Confirmation</strong></h5>
                            <p>Please select your country to proceed with the purchase. If you are from India, also provide your state.</p>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    state: null,
                                    country: ''
                                }}

                                validationSchema={isIndia ? DisplayingErrorMessagesSchemaIndia : DisplayingErrorMessagesSchema}
                                onSubmit={(values, validateForm) => {
                                    handleAddressSave(values)
                                }}

                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    handleSubmit
                                }) => {



                                    return (
                                        <form className="" onSubmit={handleSubmit}>
                                            <div className="row">
                                                {
                                                    formData?.map((fieldMeta, index) => (
                                                        <div key={'createCamp' + index} className={`col-md-12`} style={{ marginTop: 0 }}>
                                                            {
                                                                !fieldMeta?.isState || isIndia ? <label
                                                                    htmlFor={fieldMeta?.id}
                                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                                                                    style={{ fontSize: '.775rem', color: '#4d4d4d', fontWeight: 'bold' }}
                                                                >
                                                                    {fieldMeta?.title}

                                                                    {
                                                                        fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                                    }

                                                                </label> : null
                                                            }



                                                            {
                                                                fieldMeta.isCountry ?
                                                                    <div className="w-100">

                                                                        {
                                                                            countryFormatted?.length ?
                                                                                <div className="d-block w-100">

                                                                                    <CustomSelect options={countryFormatted} isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                        setFieldValue={setFieldValue}
                                                                                        type={'country'} isSelectLoading={isCountryLoading} placeholder={'Country'} />
                                                                                </div> : null
                                                                        }

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    isIndia && fieldMeta?.isState ? <div className="w-100">

                                                                        {
                                                                            indianStates?.length ?
                                                                                <div className="d-block w-100">

                                                                                    <select
                                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                        name={fieldMeta?.name}
                                                                                        id={fieldMeta?.id}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                    >
                                                                                        <option value={null} disabled selected>{'Select State'}</option>
                                                                                        {
                                                                                            indianStates?.map((state, index) => (
                                                                                                <option value={state?.name}>{state?.name}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </div> : null
                                                                        }

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div>

                                                                        : <>
                                                                            {
                                                                                !fieldMeta?.isState ? <><input
                                                                                    disabled={!fieldMeta?.editable}
                                                                                    type={fieldMeta?.type}
                                                                                    name={fieldMeta?.id}
                                                                                    autoComplete={'off'}
                                                                                    id={fieldMeta?.name}
                                                                                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                                                    placeholder={fieldMeta?.title}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    value={values[fieldMeta?.id] || ""}
                                                                                />
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div></> : null
                                                                            }
                                                                        </>
                                                            }


                                                        </div>
                                                    ))
                                                }

                                            </div>


                                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-3">
                                                <button
                                                    type="button"
                                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                                    onClick={() => { openHideAddressModal() }}
                                                >
                                                    Cancel
                                                </button>

                                                <button
                                                    type="submit"
                                                    disabled={btnLoader}
                                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                                >
                                                    {btnLoader ? (
                                                        <div className="h-5 w-5 mx-auto">
                                                            <Loadingicon />
                                                        </div>
                                                    ) : (
                                                        "Next"
                                                    )}
                                                </button>
                                            </div>
                                        </form>)
                                }
                                }

                            </Formik>
                            {/* <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-3">
                                <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={() => { openHideAddressModal() }}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    disabled={btnLoader}
                                    onClick={() => navigate(`/my-profile/edit`, { state: { comingFrom: 'plans' } })}
                                    className="bg-themecolor text-white border py-2 px-8 rounded-lg border-themecolor text-themecolor text-lg"
                                >
                                    {btnLoader ? (
                                        <div className="h-5 w-5">
                                            <Loadingicon />
                                        </div>
                                    ) : (
                                        "Edit Profile"
                                    )}
                                </button>
                            </div> */}
                        </div> : <>
                            <div className="final-step py-4 px-3">
                                <h5><strong>Plan Detail</strong></h5>

                                {
                                    taxCalcLoader ? <div className="py-4 my-3 text-center d-flex justify-content-center mx-auto w-100">
                                        <div className="h-10 w-10 theme-color">
                                            <Loadingicon />
                                        </div>
                                    </div> : <div className="w-100 color-gray">
                                        <div className="d-flex align-items-center justify-content-between py-2">
                                            <span>Plan Amount</span>
                                            <strong>₹ {taxationsDetail?.amount}</strong>
                                        </div>
                                        {
                                            isIndia ? <div className="d-flex align-items-center justify-content-between py-2">
                                                <span>Applicable Taxes (GST 18%)</span>
                                                <strong>₹ {taxationsDetail?.tax_applicable}</strong>
                                            </div> : null
                                        }

                                        <div className="d-flex align-items-center justify-content-between py-2">
                                            <span>Payment Gateway Charge</span>
                                            <strong>₹ {taxationsDetail?.payment_gateway_charge}</strong>
                                        </div>

                                        <hr />
                                        <div className="d-flex align-items-center justify-content-between py-2">
                                            <strong>Total Payable Amount</strong>
                                            <strong>₹ {taxationsDetail?.final_amount}</strong>
                                        </div>
                                    </div>

                                }
                            </div>
                            {
                                taxCalcLoader ? null : <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-3">
                                    <button
                                        type="button"
                                        className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                        onClick={() => { openHideAddressModal() }}
                                    >
                                        Cancel
                                    </button>
                                    <RazorPayPayment colors={'#ff7200'} setChoosenPlan={setChoosenPlan} params={{
                                        tuteehub_id: user.tuteehub_id,
                                        credits: choosenPlan?.coins_number,
                                        amount: taxationsDetail?.amount,
                                        platform_name: 'Razorpay',
                                        ip: user?.ip || '',
                                        tax_applicable: taxationsDetail?.tax_applicable,
                                        payment_gateway_charge: taxationsDetail?.payment_gateway_charge,
                                        final_amount: Number((taxationsDetail?.final_amount)?.replace(/,/g, '')).toFixed(2),
                                        user_agent: 'Desktop',
                                        openHideAddressModal: openHideAddressModal
                                    }} />
                                </div>
                            }


                        </>
                    }


                </Modal.Body>
            </Modal>
        </>
    );
}
export default Plans;
