import React, { useState } from 'react';
import useRazorpay from "react-razorpay";
import { RAZOR_PAY_KEY, RAZOR_PAY_KEY_TEST, USER_INFO_KEY } from '../../../utils/utils';
import { getUserInfo, onBuyCoins } from '../../../api/OurService';
import { toast } from 'react-toastify';
import { Loadingicon } from '../../../AppIcons';
import { useAuth } from '../../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

const RazorPayPayment = ({colors, setChoosenPlan, params}) => {
    
    const [Razorpay] = useRazorpay();
    const [loading, setLoading] = useState(false);
    const {user, setFinance, setUser, globalToken} = useAuth();
   
    // const order = await createOrder(params); //  Create order on your backend
    const navigate = useNavigate();
    const options = {
      key: RAZOR_PAY_KEY_TEST, // Enter the Key ID generated from the Dashboard
      amount: Number(params?.final_amount * 100).toFixed(2), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: 'TuteeHUB Solutions Pvt. Ltd.',
      description: 'TuteeHUB is a technology driven platform that enables, enhances & automates talent development & acquisition processes.',
      image: 'https://tuteehub.nyc3.cdn.digitaloceanspaces.com/thqb/images/common/logo_small.webp',
      order_id: null, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler:  async(response) => {
        // console.log(response.razorpay_payment_id);
        // alert(1)
        handleCoinBuy(response.razorpay_payment_id);
        params?.openHideAddressModal();
      },
      method:{
        upi: true
      },
      theme: {
        color: "#ff7200",
      },
    };
  
    const openPayModal = () =>{
       
        const rzp1 = new Razorpay(options);
  
        rzp1.on("payment.failed", function (response) {
          // console.log(response)
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
      
        rzp1.open();
    }

    const handleCoinBuy = async (tId) =>{   
      
        setLoading(true)
    const pParams = {
        ...params,
        description:`Payment of ${params.amount} INR successfully processed, resulting in the acquisition of ${params?.credits} credits.`,
        transaction_id:tId,
        platfrom_txn_id:tId
        }
    try {
        const res = await onBuyCoins(pParams);
        if(res?.status==1){
            toast.success(`Payment of ${params.amount} INR successfully processed, resulting in the acquisition of ${params?.credits} credits.`)
        }
        fetchUserInfo(user?.tuteehub_id)
       setChoosenPlan({})
        setLoading(false);
        navigate('/credit-purchase-history')
    } catch (error) {
        console.error("Error fetching plans:", error);
        setLoading(false);
    }
    }

    const fetchUserInfo = async(userId) =>{
        const res = await getUserInfo(userId, globalToken);      
        // localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))     
        if(res.success){
            setUser({...user, credits:res?.data?.credits})
            localStorage.setItem('userDetails',JSON.stringify({...user, credits:res?.data?.credits}))
            localStorage.setItem('____fi', JSON.stringify({__crdt:res.data?.credits}))
            setFinance({__crdt:res.data?.credits}) 
        } 
    }
   
    return (
        <div>
          <button onClick={openPayModal} disabled={!loading?false:true} className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50  flex justify-center items-center" style={{background:'white',color:colors}}>
          {loading ? (
                      <div className="h-5 w-5">
                        <Loadingicon />
                      </div>
                    ) : (
                      `Buy Now`
                    )}
          </button>
        </div>
      );
  };

export default RazorPayPayment;
