import React, { useState, useEffect, useRef } from 'react';
import { Loadingicon } from '../../AppIcons';
import * as Yup from "yup";
import { sendEmailToVerifyApi, userVerificationApi, verifyEmailApi } from '../../api/AuthService';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useAuth } from '../../Context/AuthContext';
import { useVerifyUserStatus } from '../../Context/VerifyStatusContext';
import { fetchUserInfo } from '../../utils/utils';
import downArrow from '../../assets/images/down_arrow.png';
import { generateCampaignAuto } from '../../api/OurService';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const navigation = useLocation();
  const [showStrip, setShowStrip] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState('');
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [loading, setLoading] = useState(false);
  const { user, setUser, setFinance, globalToken, isEmailVerifyCover, setIsEmailVerifyCover } = useAuth();

  const { isEmailVerified, setIsEmailVerified } = useVerifyUserStatus();
  const isMobile = window.innerWidth < 768;

  // console.log(isEmailVerified)
  const otpRef = useRef();
  const DisplayingErrorMessagesSchemaTokenVerify = Yup.object().shape({
    token: Yup.string().required("Code is required")
  });


  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos < prevScrollPos) {
      // Scrolling up, show the strip
      setShowStrip(true);
    } else {
      isMobile && !isEmailVerifyCover ? setShowStrip(false) : setShowStrip(true);
    }
    setPrevScrollPos(currentScrollPos);
  };
  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);



  useEffect(() => {
    if (isEmailVerifyCover) setShowStrip(true)
  }, [isEmailVerifyCover])

  const handleVerifyClick = async () => {

    setLoading(true)
    const sendVerifyEmailParams = {
      email: (user?.email).trim(),
      token: globalToken
    }

    try {
      const response = await sendEmailToVerifyApi(sendVerifyEmailParams);

      if (response?.success) {
        otpRef?.current?.focus();
        setIsVerified(true);
        setShowOTP(true);
        toast.success("Verification code sent on email.");
        setLoading(false)
      } else {
        toast.error(response.message || response?.errors || "Something went wrong!");
      }
    } catch (err) {
      toast.error(err?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }


  };

  const handleOTPSubmit = async (values) => {
    setLoading(true)
    const verifyEmailParams = {
      email: user?.email,
      token: values?.token,
      accessToken: globalToken
    }

    try {
      const response = await verifyEmailApi(verifyEmailParams);
      // console.log(response)
      if (response?.success) {
        // fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user)
        setIsEmailVerified(1);
        otpRef?.current?.focus();
        setIsVerified(false);
        setShowOTP(false);
        setShowStrip(false);
        toast.success("Your email Id Verified successfully.");
        await fetchUserInfo(user?.tuteehub_id, globalToken, setUser, setFinance, user);
        handleCreatCampaign();
        setLoading(false);
        getVerificationStatus()
      } else {
        toast.error(response?.message || 'Invalid token');
      }
    } catch (err) {
      // console.log(err)
      toast.error(err?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }

  };
  const getParams = () => {
    const queryParams = new URLSearchParams(navigation.search);
    const type = queryParams.get('type');
    const campaign_id = queryParams.get('campaign_id');


    return {
      type: type || null,
      campaign_id: campaign_id || null,
    }
  }

  const handleCreatCampaign = async () => {
    if (getParams()?.type && getParams()?.type == "create-campaign" && getParams()?.campaign_id) {
      let createCampaignAutoParams = await generateCampaignAuto({ token: globalToken, campaign_id: getParams().campaign_id });
      if (createCampaignAutoParams?.success) {
        toast.success('Campaign created successfully');
        navigate('/gigs/my-campaign');
      } else {
        toast.error(createCampaignAutoParams?.errors)
      }
    }
  }


  const getVerificationStatus = async () => {

    try {
      const response = await userVerificationApi({ email: user?.email });
      // alert(JSON.stringify(response))
      if (response.data.success) {
        setIsEmailVerified(1);
        // localStorage.setItem('ev_______', 1);
        // setIsEVerified(1);

        setLoading(false);
      } else {
        setIsEmailVerified(0);
        // localStorage.setItem('ev_______', 0);
        // setIsEVerified(0);
        setLoading(false);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  return (
    user?.email_verified === 0 && <div>
      {
        isEmailVerifyCover ? <div className='email-verify-cover' onClick={() => { setIsEmailVerifyCover(false) }}>
          <img src={downArrow} className='indicators' />
          <div className='close-verify-email-cover-btn' onClick={() => { setIsEmailVerifyCover(false) }}><i className='fa fa-times'></i></div>
        </div> : null
      }
      {/* Your main content */}
      {showStrip && (<div style={{ position: 'fixed', bottom: 0, right: 0, left: 0, zIndex: 10000, backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 10 }} className={`verify-email-container ${isEmailVerifyCover ? 'expand' : 'no-expand'}`}>




        <div className="bottom-strip w-100">
          {isVerified ? (
            <div className='strip-box w-100'>
              <p style={{ margin: 0 }}>Please enter the OTP sent to your registered email ID:</p>
              <Formik
                initialValues={{
                  token: ""
                }}
                validationSchema={DisplayingErrorMessagesSchemaTokenVerify}
                onSubmit={(values) => {
                  handleOTPSubmit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form className="d-flex align-items-center" onSubmit={handleSubmit} >
                    <div className='position-relative'>
                      <input
                        ref={otpRef}
                        type="text"
                        className='otp-box me-1'
                        id="token"
                        name="token"
                        placeholder='CODE'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.token}
                      />
                      <div className="text-red-500 text-sm text-right error-msg-otp min-h-[20px]">
                        {touched.token && errors.token && errors.token}
                      </div>
                    </div>
                    <button disabled={loading} type="submit" className='btnotpverify ms-0'>{loading ? (
                      <div className="h-4 w-4">
                        <Loadingicon />
                      </div>
                    ) : (
                      "Submit"
                    )}</button>
                    <button type="button" onClick={() => setIsVerified(false)} className='btnotpverify btn-default ms-2' style={{ width: 60, padding: '3px 5px', backgroundColor: '#585858' }}><i className='fa fa-times'></i></button>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div className='strip-box w-100'>
              <p style={{ margin: '0px 10px' }}>Your email verification is pending.By clicking verify button you can verify your Email Id</p>
              <button disabled={loading} className='btnotpverify' type='button' onClick={handleVerifyClick}>  {loading ? (
                <div className="h-4 w-4">
                  <Loadingicon />
                </div>
              ) : (
                "Verify"
              )}</button>
            </div>
          )}
        </div>


      </div>)}
    </div>
  );
};

export default VerifyEmail;
